import React from "react";

import { Route, Switch } from "react-router-dom";

import { routes } from "core/constants";

import Books from "./Books";
import GiftFinalScreen from "./GiftFinalScreen";
import Gifts from "./Gifts";

import AuthRoute from "../Layout/AuthRoute";

const Shop = () => (
	<Switch>
		<AuthRoute key={routes.shop.books.getElementKey()} exact path={routes.shop.books.getPath()} component={Books} />
		<Route key={routes.shop.gifts.getElementKey()} exact path={routes.shop.gifts.getPath()} component={Gifts} />
		<Route
			key={`${routes.shop.gifts.getElementKey()}/success`}
			exact
			path={`${routes.shop.gifts.getPath()}/success`}
			component={GiftFinalScreen}
		/>
	</Switch>
);

export default Shop;
