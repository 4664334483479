import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { GiftDto, IBaseState } from "@remar/shared/dist/models";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import pick from "lodash/fp/pick";
import { RootState } from "store/index";
import {
	ChangeRecipientEmailDto,
	ChangeRecipientEmailVerificationDto,
	OrderGiftCardGuestDto,
	OrderGiftCardUserDto,
	usersService
} from "store/services";

import { handleStripePayment } from "../Auth/authSlice";

type BuyGiftPayload = OrderGiftCardUserDto & {
	elements: ReturnType<typeof useElements>;
	stripe: ReturnType<typeof useStripe>;
	paymentMethodId?: string;
	cb?: () => void;
};

const getPaymentMethod = async (stripe: ReturnType<typeof useStripe>, elements: ReturnType<typeof useElements>) => {
	let paymentMethod;
	if (stripe && elements) {
		paymentMethod = await handleStripePayment(stripe, elements);
	}
	return paymentMethod;
};

interface GiftCardSlice extends IBaseState {
	giftCards: Array<GiftDto>;
}

const initialState: GiftCardSlice = {
	isLoading: false,
	error: "",
	giftCards: []
};

export const getGiftCards = createAsyncThunk("giftCard/getGiftCards", async (_, { rejectWithValue }) => {
	return await usersService.getGiftCardList({ include: ["books", "userSubscriptionType"] }).catch(rejectWithValue);
});

export const findGiftCard = createAsyncThunk("giftCard/findGiftCard", async (id: number, { rejectWithValue }) => {
	return await usersService.getGiftCardItem({ id, include: ["books"] }).catch(rejectWithValue);
});

export const buyGiftCardAsGuest = createAsyncThunk(
	"giftCard/buyGiftCardAsGuest",
	async ({ cb, ...rest }: BuyGiftPayload, { rejectWithValue }) => {
		const { stripe, elements } = rest;
		const paymentMethod = await getPaymentMethod(stripe, elements);
		const r = await usersService
			.buyGiftCardAsGuest({
				...pick(["gifterName", "gifterEmail", "recipientName", "recipientEmail", "message", "giftCardId"], rest),
				paymentMethodId: paymentMethod?.id
			} as OrderGiftCardGuestDto)
			.catch(rejectWithValue);
		cb && cb();
		return r;
	}
);

export const buyGiftCard = createAsyncThunk(
	"giftCard/buyGiftCard",
	async ({ cb, ...rest }: BuyGiftPayload, { rejectWithValue }) => {
		const skipPaymmentId = !!rest.paymentMethodId;

		let paymentMethodId;
		if (!skipPaymmentId) {
			const { stripe, elements } = rest;
			const paymentMethod = await getPaymentMethod(stripe, elements);
			paymentMethodId = paymentMethod?.id;
		}

		const r = usersService
			.byuGiftCard({
				...pick(["recipientName", "recipientEmail", "message", "giftCardId"], rest),
				paymentMethodId: skipPaymmentId ? undefined : paymentMethodId
			})
			.catch(rejectWithValue);
		cb && cb();
		return r;
	}
);

export const orderGiftCardEmailChangeVerification = createAsyncThunk(
	"giftCard/orderGiftCardEmailChangeVerification",
	async (data: ChangeRecipientEmailVerificationDto, { rejectWithValue }) => {
		return await usersService.orderGiftCardEmailChangeVerification(data).catch(rejectWithValue);
	}
);

export const orderGiftCardEmailChange = createAsyncThunk(
	"giftCard/orderGiftCardEmailChange",
	async (data: ChangeRecipientEmailDto, { rejectWithValue }) => {
		return await usersService.orderGiftCardEmailChange(data).catch(rejectWithValue);
	}
);

export const giftCardSlice = createSlice({
	name: "giftCard",
	initialState,
	reducers: {
		setError: (s, { payload }) => {
			s.error = payload;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getGiftCards.pending, s => {
				s.isLoading = true;
			})
			.addCase(getGiftCards.fulfilled, (s, { payload }) => {
				s.isLoading = false;
				s.giftCards = payload.items;
			})
			.addCase(getGiftCards.rejected, (s, { payload }) => {
				s.isLoading = false;
				s.error = payload;
			})

			.addCase(buyGiftCardAsGuest.pending, s => {
				s.isLoading = true;
			})
			.addCase(buyGiftCardAsGuest.fulfilled, s => {
				s.isLoading = false;
			})
			.addCase(buyGiftCardAsGuest.rejected, (s, { payload }) => {
				s.isLoading = false;
				s.error = payload;
			})

			.addCase(buyGiftCard.pending, s => {
				s.isLoading = true;
			})
			.addCase(buyGiftCard.fulfilled, s => {
				s.isLoading = false;
			})
			.addCase(buyGiftCard.rejected, (s, { payload }) => {
				s.isLoading = false;
				s.error = payload;
			})

			.addCase(orderGiftCardEmailChangeVerification.pending, s => {
				s.isLoading = true;
			})
			.addCase(orderGiftCardEmailChangeVerification.fulfilled, s => {
				s.isLoading = false;
			})
			.addCase(orderGiftCardEmailChangeVerification.rejected, (s, { payload }) => {
				s.isLoading = false;
				s.error = payload;
			})

			.addCase(orderGiftCardEmailChange.pending, () => {
				// todo: cover change email
			})
			.addCase(orderGiftCardEmailChange.fulfilled, () => {
				// todo: cover change email
			})
			.addCase(orderGiftCardEmailChange.rejected, () => {
				// todo: cover change email
			});
	}
});

export const { setError } = giftCardSlice.actions;

export const selectGiftCardFullState = (state: RootState): GiftCardSlice => state.giftCard;

export default giftCardSlice.reducer;
