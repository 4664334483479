import { ISocketNotificationItem, NotificationTypeId } from "@remar/shared/dist/models";
import { filterOutNotifications } from "@remar/shared/dist/utils/filterOutNotifications";
import { addMinutes, isAfter } from "date-fns";

import { getTotalMinutesFromDuration } from "../../../utils/getTotalMinutesFromDuration";

export const getLatestSentBannerNotification = (
	notifications: ISocketNotificationItem[],
	isTrial: boolean,
	hasQBOnlySubscription: boolean
): ISocketNotificationItem => {
	const filteredNotifications = filterOutNotifications({ isTrial, hasQBOnlySubscription, notifications });

	let _notification = filteredNotifications[0] as ISocketNotificationItem;
	// system notifications
	const businessNotifications = filteredNotifications.filter(
		n => n.notification.notificationTypeId === NotificationTypeId.SystemNotification
	);
	// banner notifications
	const bannerNotifications = filteredNotifications.filter(
		n => n.notification.notificationTypeId === NotificationTypeId.AdminGeneratedBanner
	);

	if (businessNotifications.length) {
		businessNotifications.forEach(n => {
			const totalMinutes: number = getTotalMinutesFromDuration(n.notification.duration!);
			const expiryDate = addMinutes(new Date(n.notification.createdAt!), totalMinutes);
			// filter out expired business banners
			if (isAfter(expiryDate, new Date())) {
				_notification = n;
			}
		});
	}

	if (bannerNotifications.length) {
		bannerNotifications.forEach(n => {
			// filter out expired onDemand banners
			if (isAfter(new Date(n.notification.sendAt as string), new Date(_notification.notification.sendAt as string))) {
				_notification = n;
			}
		});
	}

	return _notification;
};

export const getLatestSentPushNotifications = (
	notifications: ISocketNotificationItem[],
	isTrial: boolean,
	hasQBOnlySubscription: boolean
): ISocketNotificationItem => {
	const filteredNotifications = filterOutNotifications({
		isTrial,
		hasQBOnlySubscription,
		notifications,
		filterByExpiryDate: true // todo set as false and filter out notification by "read" status instead of expirydate
	});

	let _notification = filteredNotifications[0] as ISocketNotificationItem;

	if (filteredNotifications.length) {
		filteredNotifications.forEach(n => {
			if (isAfter(new Date(n.notification.sendAt as string), new Date(_notification.notification.sendAt as string))) {
				_notification = n;
			}
		});
	}

	return _notification;
};
