import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ExternalIntegrationIds, UserPaymentType } from "@remar/shared/dist/constants";
import { IBaseState, PaymentHistory } from "@remar/shared/dist/models";
import { fulfilledReducer, pendingReducer, rejectReducer } from "@remar/shared/dist/utils/reducerHelpers";
import { CardElement, useStripe } from "@stripe/react-stripe-js";
import { PaymentMethod } from "@stripe/stripe-js";
import { StripeCardElement } from "@stripe/stripe-js/types/stripe-js/elements";
import isEmpty from "lodash/isEmpty";
import { RootState } from "store";
import { UpdateBillingAddressDto, usersService } from "store/services";

import { IPaymentType } from "../Auth/auth.model";
import { getUserInfo } from "../MyAccount/myAccountSlice";
import { emit } from "../notifications/notifications.slice";

export interface CardPaymentMethod extends PaymentMethod.Card {
	id: string;
}

interface BillingState extends IBaseState {
	userPaymentProviderAccountId?: number;
	paymentMethods?: CardPaymentMethod;
	paymentHistory: PaymentHistory;
}
const initialState: BillingState = {
	isLoading: false,
	error: "",
	paymentMethods: undefined,
	paymentHistory: {
		isLoading: false,
		payments: null,
		errorMessage: "",
		page: 1,
		perPage: 10,
		totalItems: 0
	}
};

interface PaymentUpdateThunk {
	CardElement: typeof CardElement;
	element: (card: typeof CardElement) => StripeCardElement;
	stripe: ReturnType<typeof useStripe>;
	sideEffect: (error: string) => void;
}

export const getPaymentDetails = createAsyncThunk("billing/payment-methods", async (_, { rejectWithValue }) => {
	return await usersService
		.getCurrentSubscriptionPaymentMethodData({
			paymentSourceExternalIntegrationId: ExternalIntegrationIds.Stripe, // this is the Stripe integration ID, which is 1
			withPaymentSource: true // this lets the BE know we want the Stripe card data
		})
		.catch(e => rejectWithValue(e.message));
});

export const getPaymentHistory = createAsyncThunk(
	"billing/payment-history",
	async ({ page: optPage }: { page?: number }, { rejectWithValue, getState }) => {
		const {
			paymentHistory: { page }
		} = (getState() as { billing: BillingState }).billing;
		return await usersService
			.getPaymentHistory({
				page: optPage || page,
				orderBy: { createdAt: "ASC" }
			})
			.catch(e => rejectWithValue(e.message));
	}
);

export const updatePaymentMethod = createAsyncThunk(
	"billing/update-payment-method",
	async ({ CardElement, element, stripe, sideEffect }: PaymentUpdateThunk, { dispatch, rejectWithValue }) => {
		dispatch(setError(""));

		const cardElement = element(CardElement);
		if (stripe && cardElement) {
			const { error, paymentMethod } = await stripe.createPaymentMethod({ type: IPaymentType.CARD, card: cardElement });
			if (error) {
				return sideEffect("Please check your card details");
			} else if (paymentMethod) {
				try {
					await usersService.updatePaymentMethod({
						paymentProviderPaymentMethodIdentifier: paymentMethod!.id
					});
					return sideEffect("");
				} catch (e) {
					sideEffect(e.message);
					return rejectWithValue(e.message);
				}
			}
		}
	}
);

export const updateBillingAddress = createAsyncThunk(
	"billing/update-billing-details",
	async (
		{ payload, sideEffect = () => {} }: { payload: UpdateBillingAddressDto; sideEffect: () => void },
		{ rejectWithValue, dispatch }
	): Promise<void> => {
		dispatch(setError(""));
		await usersService
			.updateBillingAddress(payload)
			.then(() => {
				dispatch(
					emit({
						message: "Billing Address Updated",
						color: "success"
					})
				);
				dispatch(getUserInfo());
			})
			.catch(e => {
				dispatch(emit({ message: e.message, color: "error" }));
				return rejectWithValue(e.message);
			})
			.finally(sideEffect);
	}
);

export const BillingSlice = createSlice({
	name: "billing",
	initialState,
	reducers: {
		setError: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
		}
	},
	extraReducers: {
		[getPaymentDetails.pending.type]: state => pendingReducer(state),
		[getPaymentDetails.fulfilled.type]: (state, { payload: { id, paymentSource } }) => {
			state.userPaymentProviderAccountId = id;
			state.paymentMethods = paymentSource;
			state.isLoading = false;
		},
		[getPaymentDetails.rejected.type]: rejectReducer,
		[getPaymentHistory.pending.type]: state => {
			state.paymentHistory.isLoading = true;
		},
		[getPaymentHistory.fulfilled.type]: (state, { payload: { items, page, perPage, totalItems } }) => {
			const getName = (userPaymentTypeId, type, courseCATConfiguration, book) => {
				switch (userPaymentTypeId) {
					case UserPaymentType.Subscription:
						return type?.allowedCourses[0].name ?? "Subscription";
					case UserPaymentType.CAT:
						return courseCATConfiguration.course.name;
					case UserPaymentType.Book:
					case UserPaymentType.InvitedUser:
						return book?.data?.name ?? "-";
					case UserPaymentType.Refunded:
						return "Refunded";
					case UserPaymentType.GiftCard:
						return "Gift Card";
					default:
						return "-";
				}
			};
			const shippingDetils = (shippingData, userPaymentTypeId) => {
				if (!isEmpty(shippingData)) {
					return `order# - ${shippingData?.value?.id ?? shippingData?.response_data?.orderID}`;
				}
				switch (userPaymentTypeId) {
					case UserPaymentType.Subscription:
					case UserPaymentType.CAT:
					case UserPaymentType.Refunded:
					case UserPaymentType.InvitedUser:
						return "-";
					case UserPaymentType.Book:
						return "Digital Book";

					default:
						return "-";
				}
			};
			state.paymentHistory.payments = items.map(
				({
					amount,
					book,
					courseCATConfiguration,
					type,
					userPayment: { billingDate, invoiceUrl, shippingData },
					userPaymentTypeId,
					quantity
				}) => ({
					billingDate,
					invoiceUrl,
					amount: amount * quantity,
					shippingDetails: shippingDetils(shippingData, userPaymentTypeId),
					userPaymentTypeId,
					type: {
						name: getName(userPaymentTypeId, type, courseCATConfiguration, book)
					}
				})
			);

			state.paymentHistory.page = page;
			state.paymentHistory.perPage = perPage;
			state.paymentHistory.totalItems = totalItems;
			state.paymentHistory.isLoading = false;
		},
		[getPaymentHistory.rejected.type]: (state, { payload }) => {
			state.paymentHistory.errorMessage = payload;
			state.paymentHistory.isLoading = false;
		},
		[updatePaymentMethod.pending.type]: state => pendingReducer(state),
		[updatePaymentMethod.fulfilled.type]: fulfilledReducer,
		[updatePaymentMethod.rejected.type]: rejectReducer,
		[updateBillingAddress.pending.type]: state => pendingReducer(state),
		[updateBillingAddress.fulfilled.type]: fulfilledReducer,
		[updateBillingAddress.rejected.type]: rejectReducer
	}
});

export const getFullState = (state: RootState): BillingState => state.billing;

export const { setError } = BillingSlice.actions;

export default BillingSlice.reducer;
