import { isAfter } from "date-fns";

import { ISocketNotificationItem, NotificationAudienceTypeIdEnum } from "../models";

export const filterOutNotifications = ({
	notifications,
	isTrial,
	hasQBOnlySubscription,
	filterByExpiryDate = true
}: {
	notifications: ISocketNotificationItem[];
	isTrial?: boolean;
	hasQBOnlySubscription?: boolean;
	filterByExpiryDate?: boolean;
}): ISocketNotificationItem[] | [] => {
	const currentDate = new Date();
	const notificationAudiences = [NotificationAudienceTypeIdEnum.AllUsers];
	isTrial || hasQBOnlySubscription
		? notificationAudiences.push(NotificationAudienceTypeIdEnum.TrialUsers)
		: notificationAudiences.push(NotificationAudienceTypeIdEnum.PaidUsers);
	return notifications.filter(
		n =>
			notificationAudiences.includes(n.notification.notificationAudienceTypeId as NotificationAudienceTypeIdEnum) &&
			(filterByExpiryDate ? isAfter(new Date(n.expiresAt! || n.notification.expiresAt!), currentDate) : true)
	);
};
