import { useMemo } from "react";

import { deleteToken, getMessaging, getToken, onMessage } from "firebase/messaging";

import { FIREBASE_CONFIG } from "../../firebase/config";
import { useFirebase } from "../../firebase/useFirebase";
import { ISocketNotificationItem } from "../../models";

const { vapidKey } = FIREBASE_CONFIG;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFirebaseNotifications = () => {
	const firebaseApp = useFirebase();
	const messaging = getMessaging(firebaseApp);

	return useMemo(
		() => ({
			getFcmToken: async (setFcmTokenToStorage: (token: string) => void): Promise<void> => {
				await getToken(messaging, { vapidKey })
					.then(fcmToken => {
						if (fcmToken) {
							setFcmTokenToStorage(fcmToken);
						}
					})
					.catch(error => {
						console.error("Firebase getFcmToken()", { error });
					});
			},
			onMessageListener: (onReceive: (m: ISocketNotificationItem) => void): void => {
				onMessage(messaging, payload => {
					const notification = JSON.parse(payload?.data?.notification || "{}");
					onReceive(notification);
				});
			},
			onDeleteFcmToken: async (): Promise<void> => {
				try {
					await deleteToken(messaging);
				} catch (error) {
					console.error("Firebase onDeleteFcmToken()", { error });
				}
			}
		}),
		[messaging]
	);
};

export default useFirebaseNotifications;
