import { Color } from "@material-ui/lab";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ISocketNotificationItem, ISocketNotifications, NotificationTypeId } from "@remar/shared/dist/models";

import { AppThunk, RootState } from "store";

import { IBannerData, NotificationItem, NotificationsState } from "./notifications.model";

import { usersService } from "../../services";

const defaultDelay = 3000;

const initialState: NotificationsState = {
	items: [],
	bellNotifications: { items: [] as ISocketNotificationItem[] } as ISocketNotifications,
	bannerNotifications: { items: [] as ISocketNotificationItem[] } as ISocketNotifications,
	loadingBellNotifications: false,
	loadingBannerNotifications: false,
	bannerData: null
};

export const getUserNotifications = createAsyncThunk(
	"notifications/getUserNotifications",
	async (data: { filters: Record<string, unknown>; include?: string[]; findAll?: boolean }, { rejectWithValue }) => {
		return await usersService
			.getUserNotifications({ ...data, orderBy: { "notification.sendAt": "DESC" } })
			.catch(e => rejectWithValue(e.message));
	}
);

export const notificationsSlice = createSlice({
	name: "notifications",
	initialState,
	reducers: {
		fireEvent: (state, action: PayloadAction<NotificationItem>) => {
			state.items = [...state.items, action.payload];
		},
		dismiss: (state, action: PayloadAction<number>) => {
			state.items = state.items.filter(item => item.id !== action.payload);
		},
		dismissAll: state => {
			state.items = [];
		},
		pop: state => {
			const newState = state.items.slice();
			newState.shift();
			state.items = newState;
		},
		pushSocketNotifications: (state, { payload }: PayloadAction<ISocketNotificationItem>) => {
			const {
				notification: { notificationTypeId }
			} = payload;
			if (
				notificationTypeId === NotificationTypeId.AdminGeneratedBanner ||
				notificationTypeId === NotificationTypeId.SystemNotification
			) {
				state.bannerNotifications.items = [...state.bannerNotifications.items, payload];
			} else if (notificationTypeId === NotificationTypeId.AdminGeneratedAnnouncement) {
				//	announcements notifications
				// todo will be covered in announcement notifications feature
			} else {
				//	push/bell notifications NotificationTypeId.AdminGeneratedPush
				state.bellNotifications.items = [payload, ...state.bellNotifications.items];
			}
		},
		setBannerData: (state, { payload }: PayloadAction<IBannerData>) => {
			state.bannerData = payload;
		},
		setBannerActionLoading: (state, { payload }) => {
			if (state.bannerData) state.bannerData.isActionLoading = payload;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getUserNotifications.pending, (state, { meta: { arg } }) => {
				const { filters } = arg;
				const types: number[] = filters["notification.notificationTypeId"] as number[];
				const hasOnDemandNotificationsFilter = types.some(
					t => t === NotificationTypeId.AdminGeneratedBanner || t === NotificationTypeId.SystemNotification
				);
				const hasPushNotificationFilter = types.some(t => t === NotificationTypeId.AdminGeneratedPush);
				if (hasOnDemandNotificationsFilter) {
					state.loadingBannerNotifications = true;
				} else if (hasPushNotificationFilter) {
					state.loadingBellNotifications = true;
				}
			})
			.addCase(getUserNotifications.fulfilled, (state, { payload, meta: { arg } }) => {
				const { items: notifications } = payload;
				const { filters } = arg;
				const types: number[] = filters["notification.notificationTypeId"] as number[];
				const hasOnDemandNotificationsFilter = types.some(
					t => t === NotificationTypeId.AdminGeneratedBanner || t === NotificationTypeId.SystemNotification
				);
				const hasPushNotificationFilter = types.some(t => t === NotificationTypeId.AdminGeneratedPush);
				if (hasOnDemandNotificationsFilter) {
					// on Demand push/bell notifications
					const onDemandNotifications = notifications.filter(
						n =>
							n.notification.notificationTypeId === NotificationTypeId.SystemNotification ||
							n.notification.notificationTypeId === NotificationTypeId.AdminGeneratedBanner
					);
					state.bannerNotifications = { items: onDemandNotifications } as ISocketNotifications;
					state.loadingBannerNotifications = false;
				} else if (hasPushNotificationFilter) {
					// push/bell notifications
					const pushNotifications = notifications.filter(
						n => n.notification.notificationTypeId === NotificationTypeId.AdminGeneratedPush
					);
					state.bellNotifications = { items: pushNotifications } as ISocketNotifications;
					state.loadingBellNotifications = false;
				}
			})
			.addCase(getUserNotifications.rejected, state => {
				state.loadingBannerNotifications = false;
				state.loadingBellNotifications = false;
			});
	}
});

export const { fireEvent, dismiss, pop, dismissAll, pushSocketNotifications, setBannerData, setBannerActionLoading } =
	notificationsSlice.actions;

// DEPRECATED version, for thunks we need to pass only one argument
export const _emit =
	(message: string, color: Color, preventAutoDismiss = false): AppThunk =>
	(dispatch, getState) => {
		const state = getState();
		const lastItem = state.notifications.items[state.notifications.items.length - 1];
		const lastId = lastItem?.id + 1 || 1;
		dispatch(fireEvent({ message, type: color, id: lastId }));
		if (!preventAutoDismiss) {
			setTimeout(() => {
				dispatch(dismiss(lastId));
			}, defaultDelay);
		}
	};

export const emit = createAsyncThunk(
	"notifications/emit",
	(
		{
			message = "Unknown error",
			color,
			preventAutoDismiss = false
		}: {
			message: string;
			color: Color;
			preventAutoDismiss?: boolean;
		},
		{ dispatch, getState }
	) => {
		const state = getState() as RootState;
		const lastItem = state.notifications.items[state.notifications.items.length - 1];
		const lastId = lastItem?.id + 1 || 1;
		dispatch(fireEvent({ message, type: color, id: lastId }));
		if (!preventAutoDismiss) {
			setTimeout(() => dispatch(dismiss(lastId)), defaultDelay);
		}
	}
);

export const selectNotifications = ({ notifications }: RootState): NotificationItem[] => notifications.items;
export const getFullState = (state: RootState): NotificationsState => state.notifications;
export default notificationsSlice.reducer;
