import React, { useEffect, useMemo, useState } from "react";

import { Box, Card, Modal } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "@remar/shared/dist/components/Button";
import Stepper from "@remar/shared/dist/components/Stepper";
import useCaptchaToken from "@remar/shared/dist/hooks/useVerifyCaptcha";
import { formatUSD } from "@remar/shared/dist/utils/formatUSD";

import { useElements, useStripe } from "@stripe/react-stripe-js";
import { Form, Formik } from "formik";

import { usePostAffiliateProTrack } from "hooks/usePostAffiliateProTrack";
import isEmpty from "lodash/isEmpty";
import ReactPixel from "react-facebook-pixel";

import { useHistory, useParams } from "react-router-dom";

import { RootState, useAppDispatch, useAppSelector } from "store";
import {
	fetchCountries,
	fetchExternalIntegrationDataItems,
	setError,
	setIsLoading,
	setPapCookie,
	signUp,
	validateDuplicateEmail
} from "store/features/Auth/authSlice";
import { USA_COUNTRY_ID } from "store/features/Auth/constants";

import { UserValidateDuplicateEmailResponseDto } from "store/services";

import { PixelEvent, setTiktokEvent } from "modules/utils";

import SignUpLayout from "../SignUpLayout";
import ChangeBook from "../components/ChangeBook";
import ErrorAlert from "../components/ErrorAlert";
import FormActions from "../components/FormActions";
import { GuestShippingForm, PaymentForm } from "../components/Forms";
import { GuestSignUpError } from "../components/GuestSignUpError";
import { GuestSignUpSuccessLeft } from "../components/GuestSignUpSuccessLeft";
import { GuestSignUpSuccessRight } from "../components/GuestSignUpSuccessRight";
import GuestSummary from "../components/GuestSummary";
import { GuestShippingSchema, PaymentSchema } from "../components/schemas";
import { CardsContainer, useStyles } from "../components/styles";
import { signUpBookFormKeys, validateFormValues } from "../components/utils";

const guestInitialFormValues = {
	firstName: "",
	lastName: "",
	email: "",
	countryId: "",
	address1: "",
	address2: "",
	city: "",
	state: "",
	zipCode: "",
	phoneNumber: "",
	terms: false,
	nameOnCard: "",
	validCardDetails: undefined
};
const steps = [{ label: "Shipping" }, { label: "Payment" }];
enum Steps {
	SHIPPING,
	PAYMENT,
	SIGNUP_ERROR,
	SIGNUP_SUCCESS
}
const BookCheckOut = () => {
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const elements = useElements();
	const stripe = useStripe();
	const history = useHistory();
	const [activeStep, setActiveStep] = useState(Steps.SHIPPING);
	const { handleGenerateCaptchaToken } = useCaptchaToken();
	const [signupSuccessUserData, setSignupSuccessUserData] = useState({});
	const { errorMessage, isLoading, subExternalIntegrations, selectedShippingPlan, selectedCountryId, validatedCoupon } =
		useAppSelector((state: RootState) => state.auth);
	const { bookId } = useParams<{ bookId: string }>();

	const [bookPurchaseClaimCode, setBookPurchaseClaimCode] = useState("");
	const [changeBookModal, setChangeBookModal] = useState(false);
	const searchParams = new URLSearchParams(history.location.search); // todo: use useSearchParams
	const [duplicatedUserData, setDuplicatedUserData] = useState<UserValidateDuplicateEmailResponseDto | null>(null);

	const accountClaimCode = searchParams.get("accountClaimCode") as string;

	usePostAffiliateProTrack(v => dispatch(setPapCookie(v)));

	useEffect(() => {
		if (bookId) {
			dispatch(fetchExternalIntegrationDataItems(Number(bookId)));
			dispatch(fetchCountries(0));
		}
	}, [bookId, dispatch]);

	useEffect(() => {
		if (activeStep === Steps.SHIPPING) {
			setTiktokEvent(PixelEvent.InitiateCheckout);
			ReactPixel.track(PixelEvent.InitiateCheckout);
		}
		if (activeStep === Steps.PAYMENT) {
			setTiktokEvent(PixelEvent.AddPaymentInfo);
			ReactPixel.track(PixelEvent.AddPaymentInfo);
		}
	}, [activeStep]);

	const guestValidationSchema = useMemo(() => {
		if (activeStep === Steps.SHIPPING) return GuestShippingSchema;
		if (activeStep === Steps.PAYMENT) return PaymentSchema;
	}, [activeStep]);

	const handleGuestSubmit = (values: typeof guestInitialFormValues) => {
		dispatch(setIsLoading(true));
		return handleGenerateCaptchaToken()
			.then(token => {
				const couponCode = validatedCoupon && validatedCoupon.coupon.code;
				dispatch(
					signUp({
						guest: true,
						elements,
						stripe,
						values,
						recaptchaGoogle: token,
						couponCode
					})
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
				).then(r => {
					if (r.error) {
						dispatch(setError(r.error.message));
						setActiveStep(Steps.SIGNUP_ERROR);
					} else {
						setBookPurchaseClaimCode(r.payload?.accountClaimCode);
						setSignupSuccessUserData({
							email: r["payload"]["email"],
							firstname: r["payload"]["firstName"],
							lastname: r["payload"]["lastName"],
							shipping: formatUSD(selectedShippingPlan?.data["price"] || 0),
							discount: validatedCoupon ? formatUSD(validatedCoupon?.discountedAmount || 0) : null,
							books: subExternalIntegrations?.map(x => ({
								quantity: x.quantity,
								name: x.data.name,
								price: formatUSD(x?.data["price"])
							}))
						});
						setActiveStep(Steps.SIGNUP_SUCCESS);
					}
				});
			})
			.catch(() => dispatch(setIsLoading(false)));
	};

	const handleGuestNext = (values: typeof guestInitialFormValues) => {
		if (activeStep === Steps.SHIPPING) {
			dispatch(setIsLoading(true));
			const { email } = values;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			dispatch(validateDuplicateEmail(email)).then(r => {
				dispatch(setIsLoading(false));
				if (r.error) {
					setDuplicatedUserData({ ...r.payload, email: r.meta.arg });
					return;
				}
				dispatch(setError(""));
				setDuplicatedUserData(null);
				setActiveStep(prevActiveStep => (prevActiveStep < 1 ? prevActiveStep + 1 : Steps.PAYMENT));
			});
		} else {
			handleGuestSubmit(values);
		}
	};

	const handleBack = () => {
		setActiveStep(accountClaimCode ? Steps.SHIPPING : prevStep => (prevStep >= 1 ? prevStep - 1 : Steps.SHIPPING));
	};

	return (
		<>
			<SignUpLayout>
				<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb={5} mt={5}>
					<CardsContainer $activeStep={activeStep === Steps.SIGNUP_SUCCESS ? "column" : "column-reverse"}>
						<Card className={`${classes.card} ${classes.baseFlex}`}>
							<Box>
								<Box px={6} pt={2} className={classes.stepperForm}>
									{(activeStep === Steps.SHIPPING || activeStep === Steps.PAYMENT) &&
										selectedCountryId &&
										selectedCountryId !== USA_COUNTRY_ID && (
											<Alert style={{ margin: "16px 0" }} severity="info">
												Payment for custom fees may be required at the time of delivery.
											</Alert>
										)}
									{activeStep !== Steps.SIGNUP_SUCCESS ? <Stepper activeStep={activeStep} steps={steps} /> : null}
									<Formik
										initialValues={guestInitialFormValues}
										validationSchema={guestValidationSchema}
										validateOnChange
										onSubmit={values => {
											handleGuestNext(values);
										}}
									>
										{({
											isValid,
											values,
											dirty,
											errors,
											setErrors,
											setFieldValue,
											resetForm,
											setFieldTouched,
											handleChange
										}) => {
											let valid = isValid && isEmpty(errors) && dirty;
											if (!dirty && activeStep !== Steps.SIGNUP_ERROR && activeStep !== Steps.SIGNUP_SUCCESS) {
												valid = validateFormValues(signUpBookFormKeys[activeStep], values);
											}
											return (
												<>
													<Form>
														{activeStep === Steps.SHIPPING && <GuestShippingForm />}
														{activeStep === Steps.PAYMENT && (
															<PaymentForm
																setFieldValue={setFieldValue}
																termValue={values.terms}
																showTerms
																setTouchedField={setFieldTouched}
																handleChanged={handleChange}
															/>
														)}
														{activeStep === Steps.SIGNUP_ERROR && <GuestSignUpError errorMessage={errorMessage} />}
														{activeStep === Steps.SIGNUP_SUCCESS && (
															<GuestSignUpSuccessLeft signupSuccessUserData={signupSuccessUserData} />
														)}
													</Form>
													{errorMessage && (
														<ErrorAlert errorMessage={errorMessage} duplicatedUserData={duplicatedUserData} />
													)}
													{activeStep !== Steps.SIGNUP_ERROR && activeStep !== Steps.SIGNUP_SUCCESS ? (
														<FormActions
															back={() => {
																if (activeStep == Steps.PAYMENT) {
																	// Make sure to reset the formik state, PaymentElement is not a formik field
																	setFieldValue("validCardDetails", false).then(() => setErrors({}));
																} else setErrors({});
																handleBack();
															}}
															next={() => {
																resetForm({ values }); // resets dirty fields
																handleGuestNext(values);
															}}
															valid={valid}
															loading={isLoading}
															step={activeStep}
															lastStep={1}
														/>
													) : activeStep === Steps.SIGNUP_ERROR ? (
														<Box display="flex" justifyContent="center" mt={6}>
															<Button
																color="primary"
																variant="filled"
																onClick={() => {
																	dispatch(setError(""));
																	setActiveStep(Steps.PAYMENT);
																}}
															>
																{"Try Again"}
															</Button>
														</Box>
													) : null}
												</>
											);
										}}
									</Formik>
								</Box>
							</Box>
						</Card>
						{activeStep === Steps.SIGNUP_SUCCESS ? (
							<GuestSignUpSuccessRight bookPurchaseClaimCode={bookPurchaseClaimCode} setActiveStep={setActiveStep} />
						) : (
							<GuestSummary setChangeBookModal={setChangeBookModal} lastStep={activeStep === Steps.PAYMENT} />
						)}
					</CardsContainer>
				</Box>
			</SignUpLayout>
			<Modal
				open={changeBookModal}
				onClose={() => {
					setChangeBookModal(false);
				}}
			>
				<ChangeBook onClose={() => setChangeBookModal(false)} />
			</Modal>
		</>
	);
};

export default BookCheckOut;
