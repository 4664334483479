import React from "react";

import { Box, Typography } from "@material-ui/core";

import { formatUSD } from "@remar/shared/dist/utils/formatUSD";
import { useHistory } from "react-router-dom";

import { useAppSelector } from "store";

import { getFullState } from "store/features/Auth/authSlice";

import { RemarLogoSvg } from "assets/icons";

import {
	GiftDetailsWrapper,
	GiftFinalScreenDescription,
	GiftFinalScreenLayout,
	GiftFinalScreenTitle,
	GiftFinalScreenWrapper,
	LeftText
} from "./styles";

import { GiftIllustrationImage } from "../MyAccount/SubscriptionCancellation/styles";

const GiftFinalScreen = () => {
	const history = useHistory();
	const { isLoggedIn } = useAppSelector(getFullState);
	const {
		location: {
			state: { gifterName, gifterEmail, recipientName, recipientEmail, totalPrices }
		}
	} = history;

	return (
		<GiftFinalScreenLayout>
			<GiftFinalScreenWrapper>
				<RemarLogoSvg />
				<GiftFinalScreenTitle>Thank you for your purchase!</GiftFinalScreenTitle>
				<GiftFinalScreenDescription>
					We’ve also sent an invite email to recipient as well. You can find your purchase details below.
				</GiftFinalScreenDescription>
				<GiftDetailsWrapper>
					<LeftText>{gifterName}</LeftText>
					<Typography align="left" style={{ textAlign: "left" }}>
						{gifterEmail}
					</Typography>

					<LeftText>Recipient</LeftText>
					<Typography align="left" style={{ textAlign: "left" }}>
						{recipientName}
					</Typography>

					<LeftText>Recipient’s Email</LeftText>
					<Typography align="left" style={{ textAlign: "left" }}>
						{recipientEmail}
					</Typography>

					<LeftText>Total Price</LeftText>
					<Typography align="left" style={{ textAlign: "left" }}>
						{formatUSD(totalPrices)}
					</Typography>
				</GiftDetailsWrapper>
				<GiftIllustrationImage style={{ alignSelf: "center" }} />
			</GiftFinalScreenWrapper>
			{!isLoggedIn && <Box maxWidth={600} width="100%" borderTop={"1px solid transparent"} />}
		</GiftFinalScreenLayout>
	);
};

export default GiftFinalScreen;
