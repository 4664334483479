import { Box, Button, Card, Typography, alpha } from "@material-ui/core";

import { lighten } from "@material-ui/core/styles";

import { ReactComponent as PlusIcon } from "@remar/shared/dist/assets/icons/icon-plus.svg";

import styled from "styled-components";

import Mask from "assets/images/mask.svg";

export const BookWrapper = styled(Box)`
	margin-right: 10px;
	margin-bottom: 10px;
	width: 325px;
	${({ theme }) => theme.breakpoints.down("xs")} {
		width: 100%;
		margin-right: 0;
	}
	p {
		font-family: var(--openSans);
		color: ${props => alpha(props.theme.palette.text.primary, 0.87)};
		margin-top: 10px;
	}
	.title {
		font-size: 15px;
		font-weight: 500;
	}
	.description {
		font-size: 13px;
		font-weight: 400;
	}
	.price {
		font-family: var(--poppins);
		font-weight: 700;
		font-size: 18px;
	}
`;
export const AddContainer = styled.div`
	background: ${props => props.theme.palette.colors.primary[500]};
	width: 32px;
	display: flex;
	border-radius: 4px;
	height: 32px;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;
export const AddPackageIcon = styled(PlusIcon)`
	path {
		fill: ${props => props.theme.palette.colors.basic[100]};
	}
`;

export const CartWrapper = styled(Card)`
	position: sticky;
	top: 80px;
`;
export const MobileCartSummaryWrapper = styled(Box)`
	position: sticky;
	bottom: 0;
	padding: 5px;
	background-color: ${props => props.theme.palette.colors.basic[750]};
	width: 100%;
`;
export const CartItemWrapper = styled(Box)`
	display: flex;
	align-items: center;
	margin: 7px 0;
	background: ${props => props.theme.palette.colors.basic[1000]};
	padding: 10px;
`;
export const CartNoBooks = styled(Box)`
	display: flex;
	margin: 5px 0;
	background: ${props => props.theme.palette.colors.basic[1000]};
	padding: 15px 10px;

	.info {
		fill: ${props => props.theme.palette.colors.basic[500]};
		margin-right: 10px;
	}
	p {
		font-family: var(--openSans);
		font-size: 12px;
		font-weight: 600;
		color: ${props => props.theme.palette.colors.basic[1150]};
	}
`;

export const PlusMinus = styled(Button)`
	min-width: 0 !important;
	background: ${props => props.theme.palette.colors.basic[900]};
	color: ${props => props.theme.palette.common.white};
	display: flex;
	width: 30px;
	height: 30px;
	align-items: center;
	justify-content: center;

	&:hover {
		background: ${props => lighten(props.theme.palette.common.black, 0.5)} !important;
	}
`;

export const GiftFinalScreenTitle = styled(Typography)`
	font-family: var(--poppins);
	font-size: 22px;
	font-weight: 700;
	margin-top: 56px;
`;

export const GiftFinalScreenDescription = styled(Typography)`
	font-family: var(--openSans);
	font-size: 15px;
	font-weight: 400;
	margin-top: 24px;
`;

export const LeftText = styled(Typography)`
	font-family: var(--openSans);
	color: ${({ theme }) => alpha(theme.palette.text.primary, 0.6)};
	font-size: 15;
	text-align: right;
	font-weight: 600;
`;

export const GiftFinalScreenLayout = styled(Box)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
`;

export const GiftFinalScreenWrapper = styled(Card)`
	margin: auto;
	background-image: url(${Mask});
	background-position: right top;
	background-repeat: no-repeat;
	max-width: 600px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 80px 96px;
`;

export const GiftDetailsWrapper = styled(Box)`
	margin-top: 12px;
	display: grid;
	align-self: center;
	grid-template-columns: 1fr 1fr;
	justify-content: center;
	align-items: center;
	grid-row-gap: 16px;
	grid-column-gap: 16px;
`;
