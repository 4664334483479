import React from "react";

import { Box, Typography } from "@material-ui/core";
import { CalendarTodaySharp, ErrorSharp } from "@material-ui/icons";
import { ReactComponent as SubscriptionSvg } from "@remar/shared/dist/assets/icons/Subscription.svg";
import { ReactComponent as BadgeDollarSvg } from "@remar/shared/dist/assets/icons/badge-dollar.svg";
import { formatUSD } from "@remar/shared/dist/utils/formatUSD";
import { formatDate, getRemainingDays } from "@remar/shared/dist/utils/myAccountUtils";

import { SummaryWrapper } from "./styles";

const Summary = ({ subscriptionInfo }) => {
	const subscriptionName: string = subscriptionInfo?.subscription?.subscriptionName as string;
	const price = subscriptionInfo?.upcomingInvoice?.price;
	const expiresOn: string = subscriptionInfo?.subscription?.expiresOn;
	const billingDate = subscriptionInfo?.upcomingInvoice?.billingDate;
	const nextBillingDate = billingDate && formatDate(billingDate);
	const subscriptionEndDate =
		getRemainingDays(new Date(expiresOn)) > 1
			? `${getRemainingDays(new Date(expiresOn))} Days`
			: (expiresOn && "1 Day") || "-";

	return (
		<SummaryWrapper>
			<Typography className={"title"}>My Subscription</Typography>
			<Box className={"infoContainer"}>
				<SubscriptionSvg width={27} height={27} />
				<Box>
					<Typography className={"infoText"}>{subscriptionName || "-"}</Typography>
					<Typography className={"infoName"}>Your Subscription</Typography>
				</Box>
			</Box>
			<Box className={"infoContainer"}>
				<CalendarTodaySharp style={{ width: "27px", height: "27px", fill: "#586179" }} />
				<Box>
					<Typography className={"infoText"}>{subscriptionEndDate}</Typography>
					<Typography className={"infoName"}>Subscription Expires in</Typography>
				</Box>
			</Box>
			<Box className={"infoContainer"}>
				<BadgeDollarSvg width={27} height={27} />
				<Box>
					<Typography className={"infoText"}>
						{nextBillingDate || "-"} | {formatUSD(price | 0) || "-"}
					</Typography>
					<Typography className={"infoName"}>Your next billing date</Typography>
				</Box>
			</Box>
			<Box mt={5}>
				<Typography className={"title"}>Order Summary</Typography>
				<Box mt={2} className={"subWrapper"}>
					<Typography>
						{subscriptionInfo?.subscription?.type?.nextSubscriptionType?.duration?.months}
						{" Month(s) "}
						{subscriptionName}
					</Typography>
					<Typography>{formatUSD(price | 0)}</Typography>
				</Box>
				<Box mt={4} display={"flex"} flexDirection={"column"} alignItems={"flex-end"}>
					<Typography variant={"subtitle2"} color={"textSecondary"}>
						Your Total
					</Typography>
					<Typography variant={"h5"}>{formatUSD(price)}</Typography>
				</Box>
				<Box display="flex" mt={4} className={"disclaimer"}>
					<ErrorSharp style={{ fill: "#898f9e" }} />
					<Typography>
						{`Payment will be charged to your card on your next billing cycle which is ${nextBillingDate}`}
					</Typography>
				</Box>
			</Box>
		</SummaryWrapper>
	);
};

export default Summary;
