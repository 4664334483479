import React, { useMemo } from "react";

import { Alert } from "@material-ui/lab";
import { Link, LinkProps } from "react-router-dom";

import { useAppDispatch } from "store";
import { setError } from "store/features/Auth/authSlice";
import { UserValidateDuplicateEmailResponseDto } from "store/services";

import { routes } from "core/constants";

const LinkTo: React.FC<{ to: LinkProps["to"] }> = ({ to, children }) => {
	const dispatch = useAppDispatch();

	return (
		<Link style={{ fontWeight: "bold" }} onClick={() => dispatch(setError(""))} to={to}>
			{children}
		</Link>
	);
};

type ErrorAlertProps = {
	errorMessage: string;
	duplicatedUserData: null &
		Pick<UserValidateDuplicateEmailResponseDto, "isTrialUser" | "isVerified"> & {
			email: string;
		};
};

const ErrorAlert = ({ errorMessage, duplicatedUserData }: ErrorAlertProps) => {
	const { isTrialUser, isVerified, email } = duplicatedUserData || {};

	const isRegistered = useMemo(() => isTrialUser || isVerified, [isTrialUser, isVerified]);

	const title = useMemo(() => (isRegistered ? "Your Email already exists" : undefined), [isRegistered]);
	const message = useMemo(
		() =>
			isRegistered ? (
				<>
					Click{" "}
					<LinkTo
						to={{
							pathname: routes.signIn.getPath(),
							state: { email }
						}}
					>
						here
					</LinkTo>{" "}
					to login instead or{" "}
					<LinkTo
						to={{
							pathname: routes.forgotPassword.getPath(),
							state: { email }
						}}
					>
						click here
					</LinkTo>{" "}
					to reset your password
				</>
			) : (
				errorMessage
			),
		[isRegistered, email, errorMessage]
	);

	return (
		<Alert style={{ margin: "16px 0" }} title={title} severity="error">
			{message}
		</Alert>
	);
};

export default ErrorAlert;
