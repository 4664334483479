import React, { useMemo } from "react";

import { Box, Typography, useTheme } from "@material-ui/core";
import { ArrowForwardIos, Email, Info } from "@material-ui/icons";

import { IExtendedTheme } from "@remar/shared/dist/theme/default";

import { useAppSelector } from "store";
import { getFullState } from "store/features/Auth/authSlice";

import { InstitutionContactWrapper, StyledDollarIcon } from "./styles";

const ContactInstitution = ({ handleOfflineOption }) => {
	const theme = useTheme<IExtendedTheme>();
	const { user } = useAppSelector(getFullState);
	const accountNumber = useMemo(
		() =>
			(user && user.allowedLocations && user.allowedLocations.length > 0
				? user.allowedLocations[0].accountNumber
				: "") as string,
		[user]
	);

	const institutionEmail = useMemo(() => {
		if (!user) return "";
		if (user.allowedLocations && user.allowedLocations.length > 0) {
			if (user.allowedLocations[0].admins && user.allowedLocations[0].admins.length > 0) {
				return user.allowedLocations[0].admins[0].admin.email;
			}
		}
		return "";
	}, [user]);

	return (
		<InstitutionContactWrapper mt={4}>
			<Typography className={"request"}>
				Please transfer payment to the following bank account <br /> & provide receipt to shared email below.
			</Typography>

			<Box mt={4}>
				<Box display="flex" alignItems="center" mb={2}>
					<StyledDollarIcon width={22} height={22} />
					<Box display="flex" flexDirection="column" ml={2}>
						<Typography className={"label"}>Bank Account</Typography>
						<Typography className={"info"}>{accountNumber}</Typography>
					</Box>
				</Box>
				<Box mb={2} className={"offlineWrapper"} onClick={handleOfflineOption}>
					<Box display="flex" alignItems="center">
						<Info style={{ width: "22px", height: "22px", fill: theme.palette.colors.basic[600] }} />
						<Box display="flex" flexDirection="column" ml={2} alignItems="center">
							<Typography className={"label"} style={{ marginBottom: 0 }}>
								How Offline Payment Works?
							</Typography>
						</Box>
					</Box>
					<ArrowForwardIos style={{ fill: theme.palette.colors.basic[600] }} />
				</Box>
				<Box display="flex" alignItems="center" mb={2}>
					<Email style={{ width: "22px", height: "22px", fill: theme.palette.colors.basic[600] }} />
					<Box display="flex" flexDirection="column" ml={2}>
						<Typography className={"label"}>{institutionEmail}</Typography>
						<Typography className={"info"}>Email Address</Typography>
					</Box>
				</Box>
			</Box>
		</InstitutionContactWrapper>
	);
};

export default ContactInstitution;
