import { PaymentIntent, Stripe, StripeError } from "@stripe/stripe-js";
import { AccountStatusType, UserInfo } from "models";

import { matchPath } from "react-router-dom";

import { StripePaymentEventSatuses } from "../constants";

export const ShouldCompleteSigUp = (
	isLoggedIn: boolean,
	userAccountStatus: string,
	accountStatusType: AccountStatusType
): boolean => isLoggedIn && userAccountStatus === accountStatusType;

export interface IAccessList {
	route: string;
	restrict: boolean;
	redirect: string;
}

export const shouldRestrictAccess = (path: string, list: IAccessList[]): Omit<IAccessList, "route"> => {
	let rules = { restrict: false, redirect: "/" };
	for (const listItem in list) {
		const { route, restrict, redirect } = list[listItem];
		if (!!matchPath(path, { path: route })) {
			rules = { restrict, redirect };
		}
	}
	return rules;
};

type nameInteractionFuncType = (u: Pick<UserInfo, "firstName" | "lastName"> | null) => string;

export const getFullName: nameInteractionFuncType = userData => {
	const { firstName = "A", lastName = "A" } = userData || {};
	return `${firstName} ${lastName}`;
};
export const getInitials: nameInteractionFuncType = userData => {
	const { firstName = "A", lastName = "A" } = userData || {};
	return `${(firstName || "A")[0]}${(lastName || "A")[0]}`;
};

export const handleStripePaymentConfirmation = async (
	stripe: Stripe,
	secret: string
): Promise<{ success: boolean; error?: string; errorObject?: StripeError }> => {
	const { paymentIntent, error } = await stripe.confirmCardPayment(secret);
	if (!error && paymentIntent && paymentIntent.status === StripePaymentEventSatuses.Success) {
		return { success: true };
	} else {
		return {
			success: false,
			error: "Your payment did not succeed. Please sign in to complete purchase",
			errorObject: error
		};
	}
};

export const handleStripeAffirmConfirmation = async (
	stripe: Stripe,
	secret: string,
	paymentMethodId: string
): Promise<{ success: boolean; error?: string; errorObject?: StripeError }> => {
	if (!stripe) {
		throw new Error("Failed to confirm payment");
	}
	const { paymentIntent, error } = await stripe.confirmAffirmPayment(secret, {
		return_url: `${window.location.origin}/signUp/affirm`,
		payment_method: paymentMethodId
	});
	if (!error && paymentIntent && paymentIntent.status === StripePaymentEventSatuses.Success) {
		return { success: true };
	} else {
		return {
			success: false,
			error: "Your payment did not succeed",
			errorObject: error
		};
	}
};

export const handleRetrievePaymentIntent = async (
	stripe: Stripe,
	secret: string
): Promise<{ paymentIntent?: PaymentIntent | undefined; error?: StripeError | undefined }> => {
	try {
		return await stripe.retrievePaymentIntent(secret);
	} catch (error) {
		return error as Promise<{ paymentIntent?: PaymentIntent | undefined; error?: StripeError | undefined }>;
	}
};
