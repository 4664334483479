import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Card, Container, Typography } from "@material-ui/core";
import Button from "@remar/shared/dist/components/Button";
import Stepper from "@remar/shared/dist/components/Stepper";
import { ExternalIntegrationIds } from "@remar/shared/dist/constants";
import useCaptchaToken from "@remar/shared/dist/hooks/useVerifyCaptcha";
import { formatUSD } from "@remar/shared/dist/utils/formatUSD";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { Form, Formik } from "formik";
import isEmpty from "lodash/isEmpty";

import { useHistory, useParams } from "react-router-dom";

import { RootState, useAppDispatch, useAppSelector } from "store";

import {
	changeSubscriptionType,
	fetchCountries,
	getCourses,
	guestSignUpVerification,
	setError,
	setIsLoading,
	setSubscriptionTypesForSignup,
	signUp
} from "store/features/Auth/authSlice";
import { UserSignUpVerificationDto } from "store/services";

import { routes } from "core/constants";

import FormActions from "../components/FormActions";
import { AccountFormDetails, PaymentForm } from "../components/Forms";
import { GuestSignUpError } from "../components/GuestSignUpError";
import SignUpSuccessLeft, { SignupSuccessUserDto } from "../components/SignUpSuccessLeft";
import SignUpSuccessRight from "../components/SignUpSuccessRight";
import Summary from "../components/Summary";
import { PaymentSchema, SignupSchema } from "../components/schemas";
import { useStyles } from "../components/styles";

const initialFormValues = {
	firstName: "",
	lastName: "",
	email: "",
	password: ""
};

export default function AccountClaim() {
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const elements = useElements();
	const stripe = useStripe();
	const history = useHistory();
	const { handleGenerateCaptchaToken } = useCaptchaToken();
	const { errorMessage, isLoading, courses, guestSignUpData, shippingApplicable } = useAppSelector(
		(state: RootState) => state.auth
	);
	const { courseId, accountClaimCode } = useParams<{ courseId?: string; accountClaimCode: string }>();
	const { user: guestUser, paymentProviderAccount } = guestSignUpData as UserSignUpVerificationDto;
	const { paymentSource } = paymentProviderAccount || {};
	const { subscriptionTypes, userSubscriptionTypeId, validatedCoupon }: RootState["auth"] = useAppSelector(
		(store: RootState) => store.auth
	);

	const [activeStep, setActiveStep] = useState(0);
	const [startDate, setStartDate] = useState(new Date());
	const [signupSuccessUserData, setSignupSuccessUserData] = useState<SignupSuccessUserDto>({});
	const [, setFreeTrial] = useState(false);
	const [totalAmount, setTotalAmount] = useState(0);

	const activeSubscription = subscriptionTypes[0];

	useEffect(() => {
		dispatch(
			setSubscriptionTypesForSignup({
				courseId: courseId as unknown as number,
				isTrial: false
			})
		);
	}, [courseId, dispatch]);

	useEffect(() => {
		if (activeSubscription) {
			dispatch(changeSubscriptionType(activeSubscription?.id as number));
			dispatch(fetchCountries(activeSubscription?.id as number));
			dispatch(getCourses());
		}
	}, [activeSubscription, dispatch]);

	const sideEffect = useCallback(() => {
		history.push(`${routes.signUp.getPath()}/course`);
	}, [history]);
	useEffect(() => {
		if (accountClaimCode) {
			dispatch(guestSignUpVerification({ accountClaimCode, sideEffect }));
		}
	}, [accountClaimCode, dispatch, sideEffect]);

	const validationSchema = useMemo(() => {
		if (activeStep === 0) return SignupSchema;
		if (activeStep === 1) return PaymentSchema;
	}, [activeStep]);
	const steps = useMemo(
		() =>
			[{ label: "Account" }, { label: "Shipping" }, { label: "Payment" }].filter(
				s => s.label === "Shipping" && !isEmpty(guestUser) && !!accountClaimCode
			),
		[guestUser, accountClaimCode]
	);
	const setSignUpSuccessUser = response => {
		const mainSubTypeEIDItems = activeSubscription?.subTypeEIDItems!.find(
			i => i.integrationId == ExternalIntegrationIds.Stripe && i.parentId === null
		);
		const selectedCourse = subscriptionTypes.find(type => type.id === userSubscriptionTypeId)?.allowedCourses?.[0];
		const price = mainSubTypeEIDItems?.data["price"] || 0;
		setSignupSuccessUserData({
			email: response["payload"]["email"],
			fullName: `${response["payload"]["firstName"]} ${response["payload"]["lastName"]}`,
			courseName: selectedCourse?.name,
			price: formatUSD(price),
			discount: validatedCoupon ? formatUSD(validatedCoupon?.discountedAmount || 0) : null,
			shippingApplicable
		});
	};

	const handleBack = () => {
		setActiveStep(0);
	};

	const _handleSubmit = (values: typeof initialFormValues) => {
		dispatch(setIsLoading(true));
		return handleGenerateCaptchaToken()
			.then(async token => {
				const couponCode = validatedCoupon && validatedCoupon.coupon.code;
				try {
					const r = await dispatch(
						signUp({
							elements,
							stripe,
							accountClaimCode,
							values: { startDate, ...values },
							recaptchaGoogle: token,
							couponCode
						})
					);

					if (r["error"]) {
						dispatch(setError(r["error"]["message"]));
						setActiveStep(2);
					} else {
						setSignUpSuccessUser(r);
						setActiveStep(3);
					}
				} catch (e) {}
			})
			.catch(() => dispatch(setIsLoading(false)));
	};

	const handleNext = (values: typeof initialFormValues) => {
		if (activeStep === 0) {
			return setActiveStep(1);
		}
		_handleSubmit(values);
	};

	return (
		<Box className={classes.root}>
			<Container maxWidth="md">
				{activeStep !== 3 && (
					<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb={5} mt={5}>
						<Box className={classes.cardsContainer}>
							<Card className={classes.card}>
								<Box>
									<Box px={9} pt={2} className={classes.stepperForm}>
										<Stepper activeStep={activeStep} steps={steps} />
										<Formik
											initialValues={{
												...initialFormValues,
												...(!isEmpty(guestUser) && {
													firstName: guestUser.firstName,
													lastName: guestUser.lastName,
													email: guestUser.email
												})
											}}
											validationSchema={validationSchema}
											validateOnChange
											enableReinitialize
											onSubmit={values => {
												_handleSubmit(values);
											}}
										>
											{({ isValid, values, touched }) => {
												const neverTouched = Object.keys(touched).length === 0;
												const valid = isValid && !neverTouched;
												return (
													<>
														<Form>
															{activeStep === 0 && <AccountFormDetails confirmPassword disabledField={true} />}
															{activeStep === 1 && (
																<PaymentForm paymentSource={paymentSource} totalAmount={totalAmount} />
															)}
															{activeStep === 2 && <GuestSignUpError errorMessage={errorMessage} />}
														</Form>
														{errorMessage && (
															<Box mt={3}>
																<Typography variant="caption" style={{ color: "red" }}>
																	{errorMessage}
																</Typography>
															</Box>
														)}

														{activeStep !== 2 ? (
															<FormActions
																back={() => handleBack()}
																next={() => handleNext(values)}
																valid={valid}
																disabled={!activeSubscription}
																loading={isLoading}
																step={activeStep}
																lastStep={1}
															/>
														) : activeStep === 2 ? (
															<Box display="flex" justifyContent="center" mt={6}>
																<Button
																	color="primary"
																	variant="filled"
																	onClick={() => {
																		dispatch(setError(""));
																		setActiveStep(1);
																	}}
																>
																	{"Try Again"}
																</Button>
															</Box>
														) : null}
													</>
												);
											}}
										</Formik>
									</Box>
								</Box>
							</Card>
							<Summary
								setFreeTrial={setFreeTrial}
								courses={courses}
								startDate={startDate}
								setStartDate={setStartDate}
								showCouponInput={activeStep === 1}
								setTotalAmount={setTotalAmount}
							/>
						</Box>
					</Box>
				)}
				{activeStep === 3 && (
					<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
						<Box className={classes.successCardsContainer}>
							<Card className={classes.cardLeft}>
								<SignUpSuccessLeft signupSuccessUserData={signupSuccessUserData} />
							</Card>
							<Card className={classes.cardRight}>
								<SignUpSuccessRight />
							</Card>
						</Box>
					</Box>
				)}
			</Container>
		</Box>
	);
}
