import React, { useEffect } from "react";

import { Box } from "@material-ui/core";
import { Gift } from "@remar/shared/dist/components/GiftCard";
import { Wrapper } from "@remar/shared/dist/layouts";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { getGiftCards, selectGiftCardFullState } from "store/features/GiftCard/giftCard.slice";

import { routes } from "core/constants";

const breadcrumb = [
	{ title: "Dashboard", key: 0, link: "/" },
	{ title: "Shop", key: 1 },
	{ title: "Gifts", key: 2 }
];

const Gifts = () => {
	const dispatch = useAppDispatch();
	const isLoading = false;
	const history = useHistory();

	const { giftCards } = useAppSelector(selectGiftCardFullState);

	const handleAdd = gift => {
		history.push({
			pathname: `${routes.shop["gifts"].getPath()}/order/${gift.id}`,
			state: { gift }
		});
	};

	useEffect(() => {
		dispatch(getGiftCards());
	}, [dispatch]);

	return (
		<Wrapper heading={"Gifts"} breadcrumb={breadcrumb}>
			{isLoading ? (
				<ContentLoader />
			) : (
				<Box display={"flex"} flexDirection="row" flexWrap="wrap">
					{giftCards?.map(g => (
						<Gift key={g.id} {...g} onAdd={() => handleAdd(g)} />
					))}
				</Box>
			)}
		</Wrapper>
	);
};

export default Gifts;
