import React from "react";

import { Box, Typography } from "@material-ui/core";

import { GiftDto } from "@remar/shared/dist/models";
import { formatUSD } from "@remar/shared/dist/utils/formatUSD";
import { addDays, format } from "date-fns";

import { ShippingMessage, useStyles } from "../Auth/components/styles";

type OrderGiftCardSummaryProps = {
	gift: GiftDto;
};
const OrderGiftCardSummary = ({ gift }: OrderGiftCardSummaryProps) => {
	const classes = useStyles();

	return (
		<Box display="flex" flexDirection="column">
			<Box className={classes.summaryRoot} pb={0} style={{ position: "relative" }}>
				<Typography className={classes.title}>Order Summary </Typography>
				<Box mt={2} maxHeight="600px" height="100%">
					<Box className={classes.courseInfoWrapper}>
						<Box display={"flex"}>
							<Box display="flex" flexDirection={"column"} alignItems={"flex-start"}>
								<ShippingMessage>Course Name</ShippingMessage>
								<Typography>{gift?.data.planName}</Typography>
							</Box>
						</Box>
						<Typography />
					</Box>
					{(gift?.books || []).map((book, i) => (
						<Box className={classes.bookBlock} key={i}>
							<img alt={book.name} src={book.imageUrl} height={32} />
							<Box ml={1} display="flex" flexDirection="column" minWidth={180} alignSelf="flex-start">
								<Typography>{book.name}</Typography>
								<Box className={classes.bookChip}>Physical Book</Box>
							</Box>
							<Typography />
						</Box>
					))}
				</Box>
				<Box>
					<Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
						<Typography className={classes.summaryCostSmall}>Your Total</Typography>
						<Typography className={classes.summaryCostBig}>{formatUSD(gift?.data.price || 0)}</Typography>
					</Box>
					<ShippingMessage>
						This gift card is valid fro {gift?.validForNumberOfDays} days, buying today it will be valid till on{" "}
						{format(addDays(new Date(), gift?.validForNumberOfDays || 0), "dd. MMM yyyy")}.
					</ShippingMessage>
				</Box>
			</Box>
		</Box>
	);
};

export default OrderGiftCardSummary;
