import { Box, Grid, alpha, lighten } from "@material-ui/core";

import { ReactComponent as BadgeDollarSvg } from "@remar/shared/dist/assets/icons/badge-dollar.svg";
import styled, { css } from "styled-components";

export const PaymentWrapper = styled(Grid)`
	background: ${({ theme }) => theme.palette.background.default};
	min-height: 450px;

	${({ theme }) =>
		css`
			.StripeElement {
				background: ${theme.palette.inputField.background};
				padding: 18px 12px 19px;
				border-radius: 4px;
				&:hover {
					background: ${theme.palette.inputField.hoverBackground};
				}
				&:active {
					background: ${theme.palette.inputField.hoverBackground};
				}
			}
		`}
	.banner {
		background: ${({ theme }) => theme.palette.colors.primary[700]};
		display: flex;
		justify-content: center;
		padding: 8px;
		p {
			font-family: var(--openSans);
			font-weight: 600;
			font-size: 15px;
		}
	}

	.offlineSteps {
		height: 400px;
		overflow: auto;
		::-webkit-scrollbar-thumb {
			background-color: ${({ theme }) => theme.palette.colors.primary[700]};
		}
		::-webkit-scrollbar {
			width: 3px;
		}
	}
`;

export const SummaryWrapper = styled(Box)`
	padding: 15px;
	.title {
		font-size: 15px;
		font-weight: 600;
		font-family: var(--poppins);
		font-stretch: normal;
		font-style: normal;
		opacity: 0.87;
		line-height: 1.6;
		margin-bottom: 35px;
	}
	.infoContainer {
		width: auto;
		display: flex;
		align-items: center;
		margin-bottom: 24px;
		.infoText {
			font-size: 15px;
			font-weight: 600;
			margin-left: 18px;
			font-family: var(--poppins);
			font-stretch: normal;
			font-style: normal;
			opacity: 0.87;
			line-height: 1.6;
		}
		.infoName {
			font-size: 12px;
			font-weight: normal;
			margin-left: 18px;
			font-family: var(--poppins);
			font-stretch: normal;
			font-style: normal;
			opacity: 0.87;
			line-height: 1.33;
			color: ${({ theme }) => alpha(theme.palette.text.primary, 0.6)};
		}
	}

	.subWrapper {
		padding: 10px;
		display: flex;
		justify-content: space-between;
		background: ${({ theme }) => lighten(theme.palette.background.paper, 0.1)};
	}

	.disclaimer {
		p {
			font-family: var(--openSans);
			font-size: 12px;
			font-weight: 600;
			margin-left: 5px;
			color: ${({ theme }) => alpha(theme.palette.text.primary, 0.6)};
		}
	}
`;

export const ModalWrapper = styled(Box)`
	max-height: 90vh;
	overflow: auto;
`;

export const InstitutionContactWrapper = styled(Box)`
	p {
		font-family: var(--openSans);
	}
	.request {
		font-weight: 600;
		font-size: 13px;
		color: ${({ theme }) => alpha(theme.palette.text.primary, 0.6)};
	}
	.label {
		font-size: 15px;
		font-weight: 600;
		margin-bottom: 5px;
	}
	.info {
		font-size: 12px;
		font-weight: 400;
		color: ${({ theme }) => alpha(theme.palette.text.primary, 0.6)};
	}
	.offlineWrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: ${({ theme }) => lighten(theme.palette.background.paper, 0.1)};
		padding: 10px;
		cursor: pointer;
	}
`;

export const StyledDollarIcon = styled(BadgeDollarSvg)`
	g {
		path {
			fill: ${({ theme }) => alpha(theme.palette.text.primary, 0.6)};
		}
	}
`;
