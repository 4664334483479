import React, { useState } from "react";

import {
	Box,
	Card,
	FormControlLabel,
	Grid,
	IconButton,
	Modal,
	Radio,
	RadioGroup,
	Typography,
	useMediaQuery,
	useTheme
} from "@material-ui/core";

import { ArrowBackIos } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";

import { useStyles } from "@remar/shared/dist/styles";

import { IExtendedTheme } from "@remar/shared/dist/theme/default";

import { differenceInCalendarDays } from "date-fns";

import { useAppSelector } from "store";
import { getFullState } from "store/features/Auth/authSlice";
import { UserGetSubscriptionInfoResponseDto } from "store/services";

import CardForm from "./CardForm";
import ContactInstitution from "./ContactInstitution";
import Summary from "./Summary";
import { ModalWrapper, PaymentWrapper } from "./styles";

enum PaymentMethods {
	CARD = "card",
	INSTITUTION = "institution"
}

const AddCardDetails = ({
	onClose,
	subscriptionInfo
}: {
	onClose: () => void;
	subscriptionInfo: UserGetSubscriptionInfoResponseDto;
}) => {
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(PaymentMethods.CARD);
	const [isOfflineOptionSelected, setOfflineOptionSelected] = useState(false);
	const classes = useStyles();
	const theme = useTheme<IExtendedTheme>();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const { user } = useAppSelector(getFullState);
	const offlinePaymentProcess = (
		user && user.allowedLocations && user.allowedLocations.length > 0
			? user.allowedLocations[0].offlinePaymentProcess
			: ""
	) as string;
	const daysLeft = differenceInCalendarDays(new Date(subscriptionInfo?.subscription?.expiresOn || ""), new Date());

	const handleOfflineOption = () => setOfflineOptionSelected(true);
	return (
		<Modal className={classes.modal} disableEnforceFocus disableAutoFocus open={true} onClose={onClose}>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				width={isMobile ? "90vw" : "50vw"}
			>
				<Card className={classes.card} style={{ position: "relative", padding: 0 }}>
					<IconButton onClick={onClose} style={{ position: "absolute", right: "5px", top: "5px", zIndex: 1 }}>
						<CloseIcon color="disabled" />
					</IconButton>
					<ModalWrapper>
						<Grid container>
							<PaymentWrapper item sm={12} md={7}>
								<Box className={"banner"}>
									<Typography align={"center"}>
										Your subscription expires in {daysLeft} days. Please provide payment update.
									</Typography>
								</Box>
								<Box
									mt={2}
									p={isOfflineOptionSelected ? 2 : 3}
									style={{ paddingRight: isOfflineOptionSelected ? "0" : "24px" }}
								>
									{isOfflineOptionSelected ? (
										<>
											<Box
												ml={1}
												mb={2}
												display="flex"
												alignItems="center"
												style={{ cursor: "pointer" }}
												onClick={() => setOfflineOptionSelected(false)}
											>
												<ArrowBackIos style={{ fill: theme.palette.colors.basic[600] }} />
												<Typography>Offline Payment Process</Typography>
											</Box>
											<Box mt={2} className={"offlineSteps"} px={2}>
												<Box dangerouslySetInnerHTML={{ __html: offlinePaymentProcess }} />
											</Box>
										</>
									) : (
										<>
											<Typography variant={"h6"}>Payment</Typography>
											<Box mt={2}>
												<RadioGroup
													style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}
													value={selectedPaymentMethod}
													onChange={e => setSelectedPaymentMethod(e.target.value as PaymentMethods)}
												>
													<FormControlLabel
														value={PaymentMethods.CARD}
														control={<Radio color={"primary"} />}
														label={"Pay via Credit Card"}
													/>{" "}
													<FormControlLabel
														value={PaymentMethods.INSTITUTION}
														control={<Radio color={"primary"} />}
														label={"Contact Institution"}
													/>
												</RadioGroup>
											</Box>
											{selectedPaymentMethod === PaymentMethods.CARD ? (
												<CardForm onClose={onClose} />
											) : (
												<ContactInstitution handleOfflineOption={handleOfflineOption} />
											)}
										</>
									)}
								</Box>
							</PaymentWrapper>
							<Grid item sm={12} md={5}>
								<Summary subscriptionInfo={subscriptionInfo} />
							</Grid>
						</Grid>
					</ModalWrapper>
				</Card>
			</Box>
		</Modal>
	);
};

export default AddCardDetails;
