import { Box, ButtonBase, alpha } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import styled from "styled-components";

export const Spacer = styled.div`
	height: 1px;
	border: solid 1px ${props => props.theme.palette.colors.basic[750]};
`;

export const BookWrapper = styled(Box)`
	margin-right: 10px;
	margin-bottom: 10px;
	width: 330px;
	p {
		font-family: var(--openSans);
		color: ${props => alpha(props.theme.palette.text.primary, 0.87)};
		margin-top: 10px;
	}
	.title {
		font-size: 15px;
		font-weight: 500;
	}
	.description {
		font-size: 13px;
		font-weight: 400;
	}
	.validity {
		font-size: 13px;
		font-weight: 600;
		margin: 0;
		color: ${props => props.theme.palette.colors.basic[400]};
	}
	.price {
		font-family: var(--poppins);
		font-weight: 700;
		font-size: 18px;
	}
	.status {
		margin: 0;
		font-size: 15px;
		font-weight: 600;
	}
`;

export const EditPackageContainer = styled(ButtonBase)`
	background: ${({ theme, disabled }) =>
		disabled ? theme.palette.colors.basic[750] : theme.palette.colors.primary[500]};
	width: 32px;
	display: flex;
	border-radius: 4px;
	height: 32px;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;

export const FeatureIcon = styled(CheckCircle)`
	flex-grow: 0;
	margin-right: 4px;
	font-size: 16px;
	color: hsl(210, 94%, 72%);
`;
