import React, { useEffect } from "react";

import { ToastContainer } from "react-toastify";

import { ToastWrapper } from "./styles";

import { getFcmTokenFromStorage, setFcmTokenToStorage } from "../../api/fcm";
import { ISocketNotificationItem, NotificationDeliveryMethodEnum } from "../../models";
import useFirebaseNotifications from "../../services/useFirebaseNotifications";
import useNotificationSocketService from "../../services/useNotificationSocketService";
import "react-toastify/dist/ReactToastify.css";

type WrapperProps = {
	isLoggedIn: boolean;
	subscribeFirebaseNotifications: (fcmToken: string) => void;
	pushSocketNotifications: (data: Partial<ISocketNotificationItem>) => void;
	snackBarNotifications: React.ReactNode;
	firebaseEnabled?: boolean;
};
/**
 * A React functional component that handles the setup and management of notification systems
 * including Firebase push notifications and WebSocket connections for real-time updates.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} props.children - The child components that will be rendered within the NotificationWrapper.
 * @param {boolean} props.isLoggedIn - A flag indicating whether the user is logged in.
 * @param {Function} props.subscribeFirebaseNotifications - Function to subscribe to Firebase push notifications.
 * @param {Function} props.pushSocketNotifications - Function to push notifications received from WebSocket or Firebase to the application.
 * @param {boolean} props.firebaseEnabled - A flag indicating whether Firebase push notifications are enabled.
 * @param {React.ReactNode} props.snackBarNotifications - Notifications to be displayed in snack bar format.
 *
 * @example
 * const App = () => {
 *	 const isLoggedIn = true;
 *	 const subscribeFirebaseNotifications = (fcmToken) => { ... };
 *	 const pushSocketNotifications = (data) => { ... };
 *	 const snackBarNotifications = <div>Notification</div>;
 *
 *	 return (
 *		 <NotificationWrapper
 *			 isLoggedIn={isLoggedIn}
 *			 subscribeFirebaseNotifications={subscribeFirebaseNotifications}
 *			 pushSocketNotifications={pushSocketNotifications}
 *			 snackBarNotifications={snackBarNotifications}
 *			 firebaseEnabled={true}
 *		 >
 *			 <div>My App Content</div>
 *		 </NotificationWrapper>
 *	 );
 * };
 *
 * @example
 * const subscribe = (token) => console.log("Subscribed with token:", token);
 * const pushNotification = (data) => console.log("Push notification:", data);
 * const snacks = <span>SnackBar Notification</span>;
 *
 * <NotificationWrapper
 *	 isLoggedIn={true}
 *	 subscribeFirebaseNotifications={subscribe}
 *	 pushSocketNotifications={pushNotification}
 *	 snackBarNotifications={snacks}
 * >
 *	 <div>Your Content Here</div>
 * </NotificationWrapper>
 *
 */
const NotificationWrapper: React.FC<WrapperProps> = ({
	children,
	isLoggedIn,
	subscribeFirebaseNotifications,
	pushSocketNotifications,
	snackBarNotifications,
	firebaseEnabled
}) => (
	<>
		{firebaseEnabled ? (
			<FullNotificationWrapper
				isLoggedIn={isLoggedIn}
				subscribeFirebaseNotifications={subscribeFirebaseNotifications}
				pushSocketNotifications={pushSocketNotifications}
				snackBarNotifications={snackBarNotifications}
			>
				{children}
			</FullNotificationWrapper>
		) : (
			<SocketWrapper
				pushSocketNotifications={pushSocketNotifications}
				isLoggedIn={isLoggedIn}
				snackBarNotifications={snackBarNotifications}
			>
				{children}
			</SocketWrapper>
		)}
		<ToastWrapper>
			<ToastContainer containerId={"remar-toast"} position="bottom-right" newestOnTop />
		</ToastWrapper>
	</>
);

const FullNotificationWrapper: React.FC<Omit<WrapperProps, "firebaseEnabled">> = ({
	children,
	isLoggedIn,
	subscribeFirebaseNotifications,
	pushSocketNotifications,
	snackBarNotifications
}) => {
	const fcmToken = getFcmTokenFromStorage();
	const { getFcmToken, onMessageListener } = useFirebaseNotifications();

	// firebase push notification connection
	useEffect(() => {
		if (isLoggedIn) {
			if (fcmToken) {
				subscribeFirebaseNotifications(fcmToken);
				onMessageListener(notification =>
					pushSocketNotifications({
						notification
					})
				);
			} else {
				getFcmToken(setFcmTokenToStorage).then(() => {
					subscribeFirebaseNotifications(getFcmTokenFromStorage() as string);
					onMessageListener(notification =>
						pushSocketNotifications({
							notification
						})
					);
				});
			}
		}
	}, [fcmToken, getFcmToken, isLoggedIn, onMessageListener, pushSocketNotifications, subscribeFirebaseNotifications]);

	return (
		<SocketWrapper
			pushSocketNotifications={pushSocketNotifications}
			isLoggedIn={isLoggedIn}
			snackBarNotifications={snackBarNotifications}
		>
			{children}
		</SocketWrapper>
	);
};

type SocketWrapperProps = Pick<WrapperProps, "isLoggedIn" | "pushSocketNotifications" | "snackBarNotifications">;
const SocketWrapper: React.FC<SocketWrapperProps> = ({
	isLoggedIn,
	snackBarNotifications,
	pushSocketNotifications,
	children
}) => {
	const { subscribeNotifications, terminateSocketConnection } = useNotificationSocketService();

	// ws connection
	useEffect(() => {
		if (isLoggedIn) {
			subscribeNotifications("notification", (d: Record<string, unknown>) => {
				pushSocketNotifications({
					notification: { ...d, deliveryMethod: NotificationDeliveryMethodEnum.Push }
				});
			});
		}

		return () => {
			terminateSocketConnection(["notification"]);
		};
	}, [subscribeNotifications, terminateSocketConnection, isLoggedIn, pushSocketNotifications]);

	return (
		<>
			{children}
			{snackBarNotifications}
		</>
	);
};

export default NotificationWrapper;
