import React, { useMemo } from "react";

import { Box, Chip, List, ListItem, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Button from "@remar/shared/dist/components/Button";

import { ExternalIntegrationIds, MAX_COURSE_ADDONS } from "@remar/shared/dist/constants";
import { Course, CourseData, IValidateCouponResponse, UserSubscriptionType } from "@remar/shared/dist/models";
import { formatUSD } from "@remar/shared/dist/utils/formatUSD";

import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import { changeSubscriptionType, getFullState, validateCoupon } from "store/features/Auth/authSlice";
import { UserSubscriptionTypeCategories } from "store/features/Auth/constants";

import { ReactComponent as InfoSvg } from "assets/icons/icon-info-circle.svg";
import { routes } from "core/constants";

import { FeatureName, useChangeCourseStyles } from "./styles";

import { selectNewSubscription } from "../../../store/features/MyAccount/myAccountSlice";

const getInitialPrice = ({ subTypeEIDItems }: UserSubscriptionType) => {
	const initialOfferPrice = subTypeEIDItems?.find(
		({ integrationId, parentId }) => integrationId == ExternalIntegrationIds.Stripe && parentId === null
	);
	return formatUSD(initialOfferPrice?.data["price"]) || 0;
};

const getDuration = (o: UserSubscriptionType) => `${o.duration?.months * 30}-Day Access`;

const getRecurringPrice = ({ allowedForUST }: Course) => {
	const initialOrRecurringPrice = allowedForUST
		?.find(({ isAppleSubscription, isRecurring, isTrial }) => isRecurring && !isTrial && !isAppleSubscription)
		?.subTypeEIDItems?.find(
			({ integrationId, parentId }) => integrationId == ExternalIntegrationIds.Stripe && parentId === null
		);
	return formatUSD(initialOrRecurringPrice?.data["price"] || 0);
};

const getTrialSubscriptionTypeId = ({ allowedForUST }: Course) => {
	const allowedUST = allowedForUST?.find(
		type =>
			!type.isRecurring && type.isTrial && type.userSubscriptionTypeCategoryId == UserSubscriptionTypeCategories.Course
	);
	return allowedUST?.id;
};

type DisplayOffersProps = {
	validatedCoupon: IValidateCouponResponse | null;
	nextStep?: () => void;
	course: Course;
	isGetRecurring: boolean;
	isSigningUp: boolean;
	accountClaimCode?: string;
	onClose?: () => void;
};
const DisplayOffers = ({
	validatedCoupon,
	nextStep,
	course,
	isGetRecurring,
	isSigningUp,
	accountClaimCode,
	onClose
}: DisplayOffersProps) => {
	const classes = useChangeCourseStyles();
	const dispatch = useAppDispatch();
	const history = useHistory();

	const { data } = course;
	const { qbAvailableOfferings, selectRenewalSubscription, isLoadingSubscription } = useAppSelector(getFullState);

	const actionButtonTitle = useMemo(() => {
		if (selectRenewalSubscription) return "Select";
		if (isGetRecurring) return "Upgrade now";
		return "Buy Now";
	}, [isGetRecurring, isSigningUp]);

	const availableOfferings = useMemo(() => {
		const baseFilter = ({
			isActive,
			isRecurring,
			isTrial,
			userSubscriptionTypeCategoryId,
			isAppleSubscription,
			subTypeEIDItems
		}) =>
			isActive &&
			(isRecurring === isGetRecurring || selectRenewalSubscription) &&
			!isTrial &&
			!isAppleSubscription &&
			!!subTypeEIDItems?.length &&
			(userSubscriptionTypeCategoryId === UserSubscriptionTypeCategories.Course ||
				userSubscriptionTypeCategoryId === UserSubscriptionTypeCategories.QuestionBankOnly ||
				userSubscriptionTypeCategoryId === UserSubscriptionTypeCategories.LocationPerSeat);

		const qbOfferingsFilter = ({ id }) => qbAvailableOfferings?.some(s => s.id === id);

		const filter = qbAvailableOfferings ? baseFilter && qbOfferingsFilter : baseFilter;

		return (course.allowedForUST || []).filter(filter).sort((a, b) => {
			return a.duration && b.duration ? a.duration.months! - b.duration.months! : 0;
		});
	}, [course.allowedForUST, isGetRecurring, qbAvailableOfferings]);

	const onAction = (offer: UserSubscriptionType) => {
		if (selectRenewalSubscription) {
			dispatch(selectNewSubscription(offer.id)).then(() => {
				onClose && onClose();
			});
			return;
		}

		dispatch(changeSubscriptionType(offer.id));
		if (validatedCoupon && validatedCoupon.coupon && !validatedCoupon.discountedAmount) {
			// validates applied coupon
			dispatch(
				validateCoupon({
					couponCode: validatedCoupon.coupon.code,
					userSubscriptionTypeId: offer.id
				})
			);
		}
		if (!isSigningUp && nextStep) {
			nextStep();
			return;
		}
		if (accountClaimCode) {
			onClose && onClose();
			history.push(`${routes.signUp.getPath()}/course/${course.id}/claimAccount/${accountClaimCode}`);
		} else if (!!sessionStorage.getItem("platform")) {
			onClose && onClose();
			history.push(`${routes.signUp.getPath()}/oauth/course/${course.id}`);
		} else {
			onClose && onClose();
			history.push(`${routes.signUp.getPath()}/course/${course.id}/${offer.id}`);
		}
	};

	return (
		<Box className={classes.coursesContainer}>
			{availableOfferings.reduce((acc: React.ReactNode[], offer) => {
				if (!offer.isAppleSubscription) {
					acc.push(
						<Box className={`${classes.course} ${offer.isDefault ? classes.defaultOffer : ""}`} key={course.id}>
							<Box ml="2%" height="100%" width="100%" display="flex" flexDirection="column" alignItems="center">
								<Typography className={classes.changeCourseName}>
									{course.name !== " " ? course.name : "N/A"}
								</Typography>
								<Typography className={classes.daysAccess}>{getDuration(offer)}</Typography>
								{offer.userSubscriptionTypeCategoryId === UserSubscriptionTypeCategories.QuestionBankOnly && (
									<Chip label="Question Bank Only" color="primary" variant="outlined" />
								)}

								<Typography className={classes.changeCourseAmount} variant="h6">
									{getInitialPrice(offer)}
								</Typography>
								<List className={classes.changeCourseList}>
									{data?.marketingPoints.slice(0, MAX_COURSE_ADDONS).map((marketingPoint, i) => (
										<ListItem className={classes.changeCourseListItem} key={i}>
											<CheckCircleIcon fontSize="small" className={classes.changeCourseTick} />
											<FeatureName title={marketingPoint}> {marketingPoint} </FeatureName>
										</ListItem>
									))}
								</List>

								<Box display={"flex"} justifyContent={"center"} width="100%">
									<Button
										variant="filled"
										color="primary"
										size="small"
										className={classes.selectCourse}
										loading={isLoadingSubscription}
										onClick={() => onAction(offer)}
									>
										{actionButtonTitle}
									</Button>
								</Box>
								<Box display="flex" justifyContent="flex-start">
									<InfoSvg className={classes.changeCourseInfo} />
									<span className={classes.changeCourseListItem}>
										{data?.additionalInformation ??
											`After the first payment will be ${getRecurringPrice(course)}/month renewal.`}
									</span>
								</Box>
							</Box>
						</Box>
					);
				}
				return acc;
			}, [])}
		</Box>
	);
};

interface IProps {
	course: Course;
	isSigningUp?: boolean;
	accountClaimCode?: string;
	onClose?: () => void;
	nextStep?: () => void;
	setPickCourse?: (id: number | null) => void;
	isGetRecurring?: boolean;
}
const SelectOfferings = ({
	course,
	isSigningUp = true,
	accountClaimCode,
	onClose,
	setPickCourse,
	nextStep,
	isGetRecurring = true
}: IProps) => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const classes = useChangeCourseStyles();

	const { validatedCoupon } = useAppSelector(getFullState);

	const { hasTrial } = useMemo(() => {
		const _hasTrial = !!(course.allowedForUST || []).find(st => st.isTrial);
		return { data: course?.data as CourseData, hasTrial: _hasTrial };
	}, [course]);

	return (
		<>
			<DisplayOffers
				validatedCoupon={validatedCoupon}
				accountClaimCode={accountClaimCode}
				course={course}
				isGetRecurring={isGetRecurring}
				isSigningUp={isSigningUp}
				onClose={onClose}
				nextStep={nextStep}
			/>

			{isSigningUp && (
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					alignItems={"center"}
					className={classes.freeTrialWrapper}
					mt={2}
				>
					<Button color="basic" variant="outlined" onClick={() => setPickCourse && setPickCourse(null)}>
						Back
					</Button>
					{hasTrial && !accountClaimCode && (
						<Typography
							onClick={() => {
								dispatch(changeSubscriptionType(getTrialSubscriptionTypeId(course)));
								onClose && onClose();
								!!sessionStorage.getItem("platform")
									? history.push(`${routes.signUp.getPath()}/oauth/course/${course.id}/trial`)
									: history.push(`${routes.signUp.getPath()}/course/${course.id}/trial`);
							}}
						>
							<span>Not sure?</span> Start Free Trial
						</Typography>
					)}
				</Box>
			)}
		</>
	);
};

export default SelectOfferings;
