import { NotificationDuration } from "@remar/shared/dist/models";

export const getTotalMinutesFromDuration = (duration: NotificationDuration): number => {
	if (!duration) {
		return 0;
	}
	const { days = 0, hours = 0, minutes = 0 } = duration;
	const daysToMinutes = days * 24 * 60;
	const hoursToMinutes = hours * 60;
	return daysToMinutes + hoursToMinutes + minutes;
};
