import React, { useMemo } from "react";

import { Box, Card, Typography } from "@material-ui/core";

import { BookWrapper, EditPackageContainer, FeatureIcon, Spacer } from "./styles";

import IconClock from "../../assets/icons/icon-clock.svg";
import PencilIconSvg from "../../assets/icons/icon-pencil.svg";
import GiftIllustration from "../../assets/images/content-illustrations-gift-blue.svg";
import { GiftDto } from "../../models";

import { formatUSD } from "../../utils/formatUSD";

import Button from "../Button";
import Marker from "../Marker";

type GiftProps = GiftDto & {
	onEdit?: () => void;
	onAdd?: () => void;
	onSetActive?: () => void;
};
const GiftCard = ({
	name,
	isActive,
	validForNumberOfDays,
	userSubscriptionType,
	data,
	onEdit,
	onAdd,
	onSetActive
}: GiftProps) => {
	const { action, actionLabel } = useMemo(() => {
		if (!!onSetActive) {
			return { action: onSetActive, actionLabel: isActive ? "Unpublish" : "Publish" };
		}

		return { action: onAdd, actionLabel: "Send as Gift" };
	}, [isActive, onAdd, onSetActive]);

	return (
		<BookWrapper>
			<Card style={{ padding: "32px 20px 20px" }}>
				<Box display="flex" alignItems="center" flexDirection="column" position="relative">
					<img src={GiftIllustration} alt="Gift Illustration" />
					{onEdit && (
						<EditPackageContainer onClick={onEdit} style={{ position: "absolute", top: -16, right: 0 }}>
							<img alt={"edit"} src={PencilIconSvg} width="15px" height="15px" />
						</EditPackageContainer>
					)}
					<Box my={2} display="flex" alignItems="flex-start" flexDirection="column" width="100%">
						<Typography className={"title"}>{name}</Typography>
						<Box display="flex" alignItems="center" gridGap={10}>
							<img alt={"validity"} src={IconClock} width={16} />
							<Typography className="validity">{validForNumberOfDays} days validity</Typography>
						</Box>
					</Box>
				</Box>

				<Spacer />

				<Box>
					<Box height={80} display="flex" gridGap={10} alignItems="center">
						<FeatureIcon />
						<Typography className={"description"}>{userSubscriptionType.description}</Typography>
					</Box>
				</Box>
				<Typography className={"price"}>{formatUSD(data.price || 0)} </Typography>
				<br />
				<Spacer />

				<Box mt={2} display={"flex"} justifyContent={!!onSetActive ? "space-between" : "center"} alignItems={"center"}>
					{!!onSetActive && (
						<Box display="flex" alignItems="center" gridGap={10}>
							<Marker active={isActive} />
							<Typography className="status">{isActive ? "Published" : "Unpublished"}</Typography>
						</Box>
					)}
					<Button
						fullWidth={!onSetActive}
						size="small"
						variant={"filled"}
						color={!!onSetActive ? "danger" : "primary"}
						onClick={action}
					>
						{actionLabel}
					</Button>
				</Box>
			</Card>
		</BookWrapper>
	);
};

export default GiftCard;
