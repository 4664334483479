import React, { useEffect, useState } from "react";

import { Box, Card, Typography } from "@material-ui/core";
import { CancelRounded } from "@material-ui/icons";

import useSearchParams from "@remar/shared/dist/hooks/useSearchParams";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { handleRetrievePaymentIntent } from "@remar/shared/dist/utils/auth";

import { useStripe } from "@stripe/react-stripe-js";
import { useHistory } from "react-router-dom";

import SignUpLayout from "../SignUpLayout";
import SignUpSuccessRight from "../components/SignUpSuccessRight";
import { useStyles } from "../components/styles";

enum Steps {
	SIGNUP_ERROR,
	SIGNUP_SUCCESS
}
const SignupAffirm = () => {
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(true);
	const [responseMessage, setResponseMessage] = useState<Steps | null>(null);
	const params = useSearchParams();
	const history = useHistory();
	const payment_intent_client_secret = params.get("payment_intent_client_secret");
	const stripe = useStripe();

	useEffect(() => {
		if (payment_intent_client_secret && stripe) {
			setIsLoading(true);
			handleRetrievePaymentIntent(stripe, payment_intent_client_secret)
				.then(({ paymentIntent }) => {
					switch (paymentIntent.status) {
						case "succeeded":
							setResponseMessage(Steps.SIGNUP_SUCCESS);
							history.replace({ pathname: location.pathname, search: "" });
							break;
						case "processing":
							console.log("Payment is processing.");
							// todo show payment is processing screen
							break;
						case "requires_payment_method":
							setResponseMessage(Steps.SIGNUP_ERROR);
							history.replace({ pathname: location.pathname, search: "" });
							break;
						default:
							console.error("Unexpected status:", paymentIntent.status);
							setResponseMessage(Steps.SIGNUP_ERROR);
					}
					setIsLoading(false);
				})
				.catch(error => {
					setIsLoading(false);
					console.error("Error retrieving Payment Intent:", error);
					setResponseMessage(Steps.SIGNUP_ERROR);
				});
		}
	}, [stripe, payment_intent_client_secret]);

	if (isLoading && !responseMessage) {
		return <ContentLoader />;
	}

	return (
		<SignUpLayout>
			{responseMessage === Steps.SIGNUP_SUCCESS ? (
				<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
					<Box className={classes.successCardsContainer}>
						<Card
							className={classes.cardLeft}
							style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
						>
							<Typography className={classes.errorTitle}>{"Payment Successful"}</Typography>

							<Box mt={4} width="100%" textAlign="center" className={classes.containerGap}>
								<Typography className={classes.successDesc}>Thanks for your purchase!</Typography>
							</Box>
						</Card>
						<Card className={classes.cardRight}>
							<SignUpSuccessRight />
						</Card>
					</Box>
				</Box>
			) : (
				<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb={5} pb={6} mt={5}>
					<Box className={classes.cardsContainer}>
						<Card style={{ padding: "40px 80px" }}>
							<Box>
								<Box display="flex" justifyContent="center">
									<CancelRounded style={{ color: "#ed5465", width: "64px", height: "64px" }} />
								</Box>
								<Typography className={classes.errorTitle}>{"Something Went Wrong"}</Typography>
								<Box mt={2} flexDirection="column" display="flex" justifyContent="center">
									<Typography className={classes.errorDescription}>
										{"We are not able to process your payment"}
									</Typography>
									{<Typography className={classes.errorDescription}>{"Please try again."}</Typography>}
								</Box>
							</Box>
						</Card>
					</Box>
				</Box>
			)}
		</SignUpLayout>
	);
};

export default SignupAffirm;
