import { Box, Card, alpha } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { basic } from "@remar/shared/dist/colors";
import info from "@remar/shared/dist/colors/info";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const BodyText = styled.p`
	color: ${basic[400]};
	font-size: 1rem;
`;

export const StyledLink = styled(Link)`
	color: ${props => props.theme.palette.primary.light}!important;
`;

export const StyledBox = styled(Box)`
	padding: 0 60px 20px 60px;
	${props => props.theme.breakpoints.down("sm")} {
		padding: 0;
	}
`;

export const ModalTitleText = styled.span`
	font-family: var(--poppins);
	font-size: ${props => props.theme.typography.pxToRem(18)};
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: ${props => props.theme.palette.primary.main};
`;

export const ModalSubTitleText = styled.span`
	font-family: var(--poppins);
	font-size: ${props => props.theme.typography.pxToRem(26)};
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.23;
	letter-spacing: normal;
	color: ${props => props.theme.palette.primary.main};
	margin-bottom: 24px;
	display: flex;
`;

export const ConfirmPasswordField = styled.input`
	width: 100%;
	height: 56px;
	margin: 15px 0;
	padding: 18px 16px;
	border-radius: 4px;
	background-color: ${basic[800]};
	border: 0;
	color: ${basic[200]};
`;

export const CardWrapper = styled(Box)`
	border-radius: 2px;
	background-color: ${props => props.theme.palette.background.paper};
	width: 70%;
	margin-top: 16px;

	${props => props.theme.breakpoints.down("sm")} {
		width: 99%;
	}
`;

export const RowWrapper = styled(Box)`
	padding: 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&:first-child {
		border-bottom: solid 1px hsl(223, 10%, 58%, 0.2);
	}
	${props => props.theme.breakpoints.down("sm")} {
		padding: 24px;
		gap: 16px;
		flex-direction: column;
	}
`;

export const ContentWrapper = styled(Box)`
	width: 50%;
	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}
`;

export const CircularBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 500px;
	width: 100%;
`;

export const UpgradeSubscriptionCard = styled(Card)`
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	background: ${({ theme }) => theme.palette.background.paper};
	min-height: 630px;
	overflow: unset;
	box-shadow: none;

	${props => props.theme.breakpoints.down("sm")} {
		min-height: 100%;
	}
`;

export const useStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		root: {
			width: "100%",
			display: "flex",
			[theme.breakpoints.down("sm")]: {
				flexDirection: "column",
				marginRight: 0
			}
		},
		userInfoCard: {
			[theme.breakpoints.down("sm")]: {
				padding: "8px",
				width: "100%"
			}
		},
		userInfoContainer: {
			[theme.breakpoints.down("sm")]: {
				marginRight: 0,
				width: "100%"
			}
		},
		subscriptionCard: {
			[theme.breakpoints.down("sm")]: {
				width: "100%"
			}
		},
		subscriptionInfoContainer: {
			[theme.breakpoints.down("sm")]: {
				marginTop: "24px",
				width: "100%"
			}
		},
		cardsContainer: {
			display: "flex",
			width: "100%",
			maxHeight: "95vh",
			overflowY: "scroll",
			background: theme.palette.background.paper,
			[theme.breakpoints.down("xs")]: {
				flexFlow: "row wrap-reverse",
				overflow: "scroll"
			}
		},
		selectOfferContainer: {
			display: "flex",
			flexDirection: "column",
			width: "100%",
			background: theme.palette.background.paper,
			padding: "15px 0"
		},
		container: {
			[theme.breakpoints.down("xs")]: {
				height: "100%",
				overflow: "hidden"
			}
		},
		noCancel: {
			backgroundColor: "transparent",
			color: alpha(theme.palette.primary.main, 0.6),
			borderColor: "hsl(222,4%,44%)",
			borderWidth: "1px",
			borderStyle: "solid",
			marginRight: "10px"
		},
		breadcrumb: {
			color: alpha(theme.palette.primary.main, 0.6),
			fontSize: theme.typography.pxToRem(13)
		},
		breadcrumbActive: {
			color: "hsl(224,7%,32%)",
			fontSize: theme.typography.pxToRem(13)
		},
		buttonContainer: {
			display: "flex",
			gap: "10px",
			[theme.breakpoints.down("xs")]: {
				flexDirection: "column",
				justifyContent: "center",
				width: "100%",
				paddingRight: "5px"
			}
		},

		header: {
			width: "100%",
			maxWidth: "1124px",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			marginBottom: 57,
			marginTop: 20,
			[theme.breakpoints.down("xs")]: {
				flexDirection: "column",
				gap: 16,
				marginTop: 69
			}
		},
		dateText: {
			fontSize: 12,
			fontWeight: "normal",
			marginLeft: 18,
			color: info[500],
			cursor: "pointer"
		},
		title: {
			fontSize: 25,
			fontWeight: 900,
			fontFamily: "var(--poppins)",
			fontStretch: "normal",
			fontStyle: "normal",
			opacity: 0.87,
			[theme.breakpoints.down("xs")]: {
				alignSelf: "flex-start",
				fontSize: 20
			}
		},
		cardHeader: {
			display: "flex",
			alignItems: "center",
			marginTop: 20,
			marginLeft: 20
		},
		imgAndIconContainer: {
			position: "relative",
			width: 96
		},
		img: {
			display: "block"
		},
		callbackImg: {
			justifyContent: "center",
			display: "flex",
			alignItems: "center",
			height: "96px",
			width: "96px",
			borderRadius: "4px",
			backgroundColor: "hsl(200,100%,97%)",
			color: "hsl(199,79%,47%)"
		},
		initials: {
			color: "hsl(199,79%,47%)",
			fontSize: `"${theme.typography.pxToRem(30)}"`,
			textTransform: "uppercase"
		},
		editIcon: {
			width: 25,
			height: 25,
			backgroundColor: "hsl(226,17%,85%)",
			position: "absolute",
			top: 0,
			right: 0,
			borderRadius: 4,
			marginTop: 1,
			marginRight: 1,
			padding: 5
		},
		nameContainer: {
			display: "flex",
			flexDirection: "column"
		},
		name: {
			fontSize: 22,
			fontWeight: 900,
			marginLeft: 24,
			fontFamily: "var(--poppins)",
			fontStretch: "normal",
			fontStyle: "normal",
			opacity: 0.87
		},
		cardBody: {
			marginTop: 56,
			marginLeft: 20
		},
		bodyRow: {
			display: "flex",
			gap: "24px",
			[theme.breakpoints.down("xs")]: {
				flexDirection: "column"
			}
		},
		infoContainer: {
			width: "auto",
			display: "flex",
			alignItems: "center",
			marginBottom: 24
		},
		infoText: {
			fontSize: 15,
			fontWeight: 600,
			marginLeft: 18,
			fontFamily: "var(--poppins)",
			fontStretch: "normal",
			fontStyle: "normal",
			opacity: 0.87,
			lineHeight: 1.6,
			[theme.breakpoints.down("xs")]: {
				width: "40vw"
			}
		},
		infoName: {
			fontSize: 12,
			fontWeight: "normal",
			marginLeft: 18,
			fontFamily: "var(--poppins)",
			fontStretch: "normal",
			fontStyle: "normal",
			opacity: 0.87,
			lineHeight: 1.33,
			color: alpha(theme.palette.text.primary, 0.6)
		},
		subscriptionTitle: {
			fontSize: 15,
			fontWeight: 600,
			fontFamily: "var(--poppins)",
			fontStretch: "normal",
			fontStyle: "normal",
			opacity: 0.87,
			lineHeight: 1.6,
			marginBottom: 35
		},
		cancelSubscriptionContainer: {
			width: "100%",
			padding: "23px 16px 16px",
			borderTop: "solid 1px rgb(137, 143, 158)",
			display: "flex",
			justifyContent: "center"
		},
		card: {
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
			background: "hsl(224,18%,12%)",
			flex: 5,
			minHeight: 630,
			[theme.breakpoints.down("xs")]: {
				minHeight: "100%"
			}
		},
		filledFormTitle: {
			fontSize: theme.typography.pxToRem(26),
			fontWeight: 700,
			fontFamily: "var(--poppins)"
		}
	})
);

export const useChangePasswordStyles = makeStyles((theme: IExtendedTheme) => ({
	title: {
		fontWeight: "bold",
		fontSize: `"${theme.typography.pxToRem(18)}"`,
		color: theme.palette.text.primary,
		alignItems: "center",
		display: "flex"
	},
	heading: {
		fontSize: `"${theme.typography.pxToRem(26)}"`,
		fontWeight: "bold",
		color: theme.palette.text.primary,
		alignItems: "center",
		display: "flex",
		marginBottom: "20px"
	},
	root: {
		flexGrow: 1,
		padding: "40px 50px 20px 50px",
		[theme.breakpoints.down("xs")]: {
			padding: 0
		}
	},
	listStyle: { listStyle: "none", padding: 0 },
	listItem: {
		fontSize: `${theme.typography.pxToRem(15)}`,
		fontWeight: "normal",
		lineHeight: 1.6,
		color: theme.palette.text.primary,
		gap: "15px",
		display: "flex",
		alignItems: "center"
	},
	passContain: {
		fontSize: `${theme.typography.pxToRem(13)}`,
		fontWeight: 600,
		color: theme.palette.text.primary,
		opacity: 0.6
	}
}));

export const useEditProfilePictureStyles = makeStyles((theme: IExtendedTheme) => ({
	title: {
		fontWeight: "bolder"
	},
	card: {
		width: "calc(50%)",
		[theme.breakpoints.down("sm")]: {
			width: "100%"
		}
	},
	cancel: {
		color: alpha(theme.palette.text.primary, 0.6)
	},
	save: {
		marginLeft: "10px"
	}
}));

export const CancelSubModalWrapper = styled(Box)`
	.header {
		position: absolute;
		top: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 10px;
	}

	${p => p.theme.breakpoints.down("xs")} {
		padding-top: 200px;
	}
`;
