import React from "react";

import { NotificationsWrapper as SharedNotificationsWrapper } from "@remar/shared/dist/components/Notifications";
import { useAppDispatch, useAppSelector } from "store";
import { selectLogedIn, subscribeFirebaseNotifications } from "store/features/Auth/authSlice";
import { pushSocketNotifications } from "store/features/notifications/notifications.slice";

import SnackBarNotifications from "core/Notifications";

const NotificationsWrapper: React.FC = ({ children }) => {
	const dispatch = useAppDispatch();
	const isLoggedIn = useAppSelector(selectLogedIn);

	return (
		<SharedNotificationsWrapper
			subscribeFirebaseNotifications={fcmToken => dispatch(subscribeFirebaseNotifications(fcmToken))}
			pushSocketNotifications={notificationItem => dispatch(pushSocketNotifications(notificationItem))}
			isLoggedIn={isLoggedIn}
			snackBarNotifications={<SnackBarNotifications />}
			firebaseEnabled={true}
		>
			{children}
		</SharedNotificationsWrapper>
	);
};

export default NotificationsWrapper;
