import { BaseModel } from "./base.model";
import { LocationPackageTypeModel } from "./locationPackageType.model";
import { SubscriptionDuration } from "./userSubscriptionType.model";
import { StripeIntegrationData } from "./userSubscriptionTypeExternalIntegrationDataItems.model";

export interface AllowedForUST {
	id: number;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	nextSubscriptionTypeId: number;
	name: string;
	description: string;
	isActive: boolean;
	isTrial: boolean;
	isRecurring: boolean;
	duration: {
		days: number;
		months: number;
	};
	userSubscriptionTypeCategoryId: number;
	subTypeEIDItems: {
		id: number;
		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
		integrationId: number;
		subscriptionTypeId: number;
		parentId: string | null;
		data: {
			name: string;
			price: number;
			planId: string;
			currency: string;
			planName: string;
		};
		mainImageKey: string | null;
		isForBook: boolean;
		mainImageUrl: string;
	}[];
}
export interface LocationPackage extends BaseModel {
	id: number;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	name: string;
	locationPackageTypeId: number;
	locationPackageType: LocationPackageTypeModel;
	courseId: number;
	isActive: boolean;
	isDeleted: boolean;
	description: string[];
	allowedUST: AllowedForUST;
	allowedSeatRecurringUST: AllowedForUST;
	allowedUSTSeat: AllowedForUST;
}

export interface NextUserSubscriptionType extends BaseModel {
	description: string;
	duration: SubscriptionDuration;
	isActive: true;
	isRecurring: boolean;
	isTrial: boolean;
	name: string;
	nextSubscriptionTypeId: number;
	subTypeEIDItems: Array<SubTypeEIDItem>;
	userSubscriptionTypeCategoryId: number;
}

interface SubTypeEIDItem extends BaseModel {
	data: StripeIntegrationData;
	integrationId: number;
	isForBook: boolean;
	mainImageKey: string | null;
	mainImageUrl: string;
	parentId: string | null;
	subscriptionTypeId: number;
}

export enum Integrations {
	StripeIntegrationId = 1, // eslint-disable-line no-unused-vars
	AcutrackIntegrationId = 2, // eslint-disable-line no-unused-vars
	DigitalAssetIntegrationId = 3, // eslint-disable-line no-unused-vars
	PrintBindShipIntegrationId = 4 // eslint-disable-line no-unused-vars
}
