import {
	CourseChapterSectionLesson,
	QuestionBankSubject,
	UserCustomTest,
	UserSubscrptnType
} from "@remar/shared/dist/models";

export interface Duration {
	days: number;
}

export interface InactiveSubscription {
	subscriptionId?: number;
	isRenewEnabled: boolean;
	typeId?: number;
	nextUserSubscriptionType: UserSubscrptnType;
}

export interface IStudyGuide {
	subjects: QuestionBankSubject[];
	lessons: CourseChapterSectionLesson[];
}

export interface IStudyGuideResponse {
	test?: UserCustomTest;
	studyGuide?: IStudyGuide;
}

export enum IPaymentType {
	CARD = "card",
	AFFIRM = "affirm"
}
