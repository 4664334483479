import { BaseModel } from "./base.model";
import { IPaymentDiscount } from "./coupon.model";
import { Course } from "./course.model";
import { Lesson } from "./lesson.model";
import { UserShippingDetailsItem } from "./userShippingDetailsItem.model";
import { UserSubscription } from "./userSubscription.model";

export interface LocationTheme extends Pick<BaseModel, "id"> {
	color: string;
	name: string;
	data: Record<string, unknown>;
}

interface UserType extends BaseModel {
	canSignUp: boolean;
	isActive: boolean;
	isEditable: boolean;
	name: string;
	signupSubscriptionTypeId?: number;
}

export interface UserShippingDetails extends BaseModel {
	address1: string;
	address2: string;
	city: string;
	countryId: number;
	fullName: string;
	phoneNumber: string;
	state: string;
	userId: number;
	zipCode: string;
}

export interface UpdateUser extends Pick<User, "firstName" | "lastName" | "email"> {}
export interface User extends BaseModel {
	acceptedTermsAndConditions: boolean;
	accountStatus: AccountStatus;
	accountStatusId: number;
	allowedLocations?: AllowedLocations[];
	assignedUserTypes?: UserType[];
	badges: Array<Badge>;
	canAccessCourse: boolean;
	canAccessQuestionBank: boolean;
	canAccessQuiz: boolean;
	currentAccessControlPoints?: Record<string, unknown>[];
	currentlyAllowedFullCourses?: Course[];
	currentlyAllowedLessons?: Lesson[];
	currentlyAllowedTrialCourses?: Course[];
	email: string;
	examInfo?: UserExamInfo;
	expiredSubscriptions?: UserSubscription[];
	firstName: string;
	hasTakenIntro: boolean;
	isCATAvailable: boolean;
	lastName: string;
	managedLocations?;
	nextUserSubscriptionType?: UserSubscription;
	paymentAndDiscount: IPaymentDiscount;
	profileImageUrl: string;
	redirectUrl: string;
	schoolId: number;
	subscriptions?: UserSubscription[];
	trialAccess: boolean;
	unverifiedEmail?: string;
	userAuthTypeId?: number;
	userShippingDetails?: UserShippingDetails;
	subscriptionPaymentIntentClientSecret?: string;
	locationStudentSponsorship?: {
		isUsed: boolean;
		isCanceled: boolean;
	}[];
}

export interface UserInfo extends Pick<User, "firstName" | "lastName" | "email" | "profileImageUrl"> {
	acceptedTermsAndConditions?: boolean;
	address: string;
	badges?: Badge[];
	fullName?: string;
	phoneNumber: string;
	schoolName: string;
	shippingDetails?: UserShippingDetailsItem;
}

export interface AdminInfo extends Pick<User, "firstName" | "lastName" | "email"> {}
export interface Admin {
	adminId: number;
	locationId: number;
	isSuperAdmin: boolean;
	admin: AdminInfo;
}
interface AllowedLocations extends BaseModel {
	canSignUp: boolean;
	isCommon: boolean;
	name: string;
	themeId: number | null;
	totalQuestions: number;
	totalStudents: number;
	admins: Admin[];
	theme?: LocationTheme;
}

export enum AccountStatusType {
	SignupPending = "SignupPending",
	Active = "Active"
}
export enum AccountStatusEnum {
	Active = 1,
	Inactive = 2,
	SignupPending = 3
}
export interface AccountStatus extends BaseModel {
	deletedAt: string | null;
	name: string;
	label: AccountStatusType;
	userLoginAllowed: boolean;
}

export interface UserExamInfo {
	nclexTimesTaken?: number;
	dateOfExam?: string;
}

export interface UserCertificate extends BaseModel {
	courseId: number;
	certificatePdf: string;
	userId: number;
	percentage: number | null;
	certificatePdfUrl: string;
}

export interface Badge extends BaseModel {
	userId: number;
	typeId: UserBadgeTypeEnum;
}

export enum UserBadgeTypeEnum {
	ReMarReadyBadge = 1,
	InactivityBadge = 2
}

export interface BadgeInfo {
	catPassed: boolean;
	isCourseComplete: boolean;
	courseCompletionPercentage: number;
	timeSpentTraining: number;
	timeSpentRequired: number;
	percentage: number;
	receivedOn: string;
}

export interface IPasswordBoxValidation {
	characters: boolean;
	lowerCase: boolean;
	upperCase: boolean;
	specialCharacter: boolean;
	numbers: boolean;
}

export interface AssignedUserTypes extends BaseModel {
	name: string;
	isActive: boolean;
}
export interface InviteData extends BaseModel {
	firstName: string;
	lastName: string;
	email: string;
	isVerified: boolean;
	assignedUserTypes: AssignedUserTypes[];
}
