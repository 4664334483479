import React, { CSSProperties, useState } from "react";

import {
	Box,
	Checkbox,
	Divider,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

import { v4 as uuid } from "uuid";

import ColumnFilterWrapper from "./ColumnFilterWrapper";

import { IRowProps, ITableProps, alignments, sortDirectionEnum } from "./columnFilters/types";

import {
	ClosedChevron,
	SortArrowWrapper,
	StyledCell,
	StyledSubCell,
	StyledTableRow,
	StyledTableSubRow,
	useStyles
} from "./styles";

import ChevronDownSvg from "../../assets/icons/icon-chevron-down.svg";
import FileCheck from "../../assets/icons/icon-file-check.svg";
import FolderOpen from "../../assets/icons/icon-folder-open.svg";
import FolderClosed from "../../assets/icons/icon-folderSVG.svg";

const Row = ({
	item,
	childItems,
	columns,
	index,
	customCellStyle,
	disabled,
	showNestedRowsIcon = false,
	onRowClick,
	onRowSelect,
	selectedRows
}: IRowProps) => {
	const { hasChildren } = item;
	const [isExpanded, setIsExpanded] = useState(!!item.isExpanded);
	const checked = selectedRows && selectedRows.some(r => r.id === item.id);
	const _columns = [...columns];
	if (onRowSelect) {
		_columns.unshift({
			alignment: "center",
			label: "",
			width: 40,
			Cell: ({ rowData }) => (
				<Checkbox color={"primary"} checked={checked} onChange={() => onRowSelect && onRowSelect(rowData)} />
			),
			dataKey: "row_select"
		});
	}
	return (
		<>
			<StyledTableRow onClick={() => onRowClick && onRowClick(item)}>
				{_columns.map(({ Cell, width, alignment }, columnIndex) => {
					const canExpand = columnIndex === 0;
					return (
						<StyledCell
							width={width}
							key={uuid()}
							align={alignment}
							$disabled={disabled}
							style={{ ...customCellStyle }}
						>
							{canExpand && hasChildren && (
								<IconButton
									style={{
										width: "40px",
										height: "40px",
										marginRight: "5px",
										background: "#262a34"
									}}
									onClick={() => hasChildren && setIsExpanded(prevState => !prevState)}
									disabled={!childItems.length}
								>
									<ClosedChevron src={ChevronDownSvg} alt="chevron" isExpanded={isExpanded} />
								</IconButton>
							)}
							{canExpand && !showNestedRowsIcon && <div style={{ marginRight: "22px" }} />}
							{canExpand &&
								showNestedRowsIcon &&
								(hasChildren ? (
									<img
										src={isExpanded ? FolderOpen : FolderClosed}
										alt={isExpanded ? "Folder open" : "Folder close"}
										width={20}
										height={20}
										style={{ marginRight: "10px" }}
									/>
								) : (
									<img
										src={FileCheck}
										alt="File check"
										width={20}
										height={20}
										style={{ marginRight: "10px", marginLeft: "45px" }}
									/>
								))}
							{Cell({ rowData: item, rowIndex: index })}
						</StyledCell>
					);
				})}
			</StyledTableRow>
			{hasChildren &&
				childItems.map(child => (
					<StyledTableSubRow key={uuid()} $isExpanded={isExpanded}>
						{columns.map(
							({ ChildCell, width, alignment, childCellColSpan }) =>
								ChildCell && (
									<StyledSubCell
										key={uuid()}
										width={width}
										align={alignment}
										$disabled={disabled}
										style={{ ...customCellStyle }}
										{...(childCellColSpan && { colSpan: childCellColSpan })}
									>
										{ChildCell({ parentRowData: item, rowData: child, rowIndex: index })}
									</StyledSubCell>
								)
						)}
					</StyledTableSubRow>
				))}
		</>
	);
};

/*
to use nested rows in tables

<MaterialTable data={[
  {…data,
    hasChildren:true, // `true` if a row has children
    childItems:{[…children]} // children data array
    isExpanded: false // `true` if a parent row should be expanded
  }
  ]}
  showNestedRowsIcon={true} // show/hide folder icons for parent rows
  />
*/

const mobileMenuStyle: CSSProperties = {
	position: "absolute",
	right: "10px",
	top: "0",
	bottom: "0",
	margin: "auto 0",
	height: "50px"
};
export const MaterialTable = <T extends Record<string, unknown>>({
	columns,
	data,
	customStyle,
	height,
	hideHeader = false,
	customTableStyle,
	rowCustomCellStyle,
	customCellStyle,
	disableRow,
	showNestedRowsIcon,
	showMobileView,
	onRowClick,
	onRowSelect,
	onAllRowsSelect,
	selectedRows,
	selectedRowsActions
}: ITableProps<T>) => {
	const classes = useStyles();
	const _columns = [...columns];
	if (onAllRowsSelect) {
		// select all
		const checked = selectedRows?.length === data.length;
		_columns.unshift({
			alignment: "center",
			label: <Checkbox color={"primary"} checked={checked} onChange={() => onAllRowsSelect && onAllRowsSelect()} />,
			width: 40,
			dataKey: "select_all"
		});
	}
	return (
		<TableContainer className={classes.tableContainer} component={Paper} style={{ height, ...customStyle }}>
			{showMobileView ? (
				<Box display={"flex"} flexDirection={"column"}>
					{data.map(item => (
						<Box key={uuid()} m={1} style={{ position: "relative" }} onClick={() => onRowClick && onRowClick(item)}>
							{_columns.map(({ Cell, dataKey }, index) => (
								<Box
									key={uuid()}
									mb={1}
									style={dataKey === "menu" ? mobileMenuStyle : {}}
									onClick={e => dataKey === "menu" && e.stopPropagation()}
								>
									{Cell && Cell({ rowData: item, rowIndex: index })}
								</Box>
							))}
							<Divider />
						</Box>
					))}
				</Box>
			) : (
				<>
					{selectedRows && selectedRows.length > 0 && (
						<Box
							display={"flex"}
							justifyContent={"space-between"}
							alignItems={"center"}
							width={"100%"}
							style={{ padding: "8px 16px" }}
						>
							<Typography variant={"subtitle1"} color={"textSecondary"}>
								{selectedRows.length} row(s) selected
							</Typography>
							<Box>{selectedRowsActions}</Box>
						</Box>
					)}
					<Table className={classes.table} style={{ ...customTableStyle }} aria-label="simple table">
						{!hideHeader && (
							<TableHead className={classes.theader}>
								<TableRow>
									{_columns.map(({ label, dataKey, alignment, filter, sort, sortDirection }) => {
										return (
											<TableCell
												key={dataKey}
												align={alignment as alignments}
												style={{ ...customCellStyle, padding: "8px", cursor: sort ? "pointer" : "unset" }}
												onClick={() => {
													if (sort) {
														const reverseDirection =
															sortDirection === sortDirectionEnum.ASC ? sortDirectionEnum.DESC : sortDirectionEnum.ASC;
														// sets direction to null on the third click to reset column sort
														const updatedDirection = sortDirection === sortDirectionEnum.DESC ? null : reverseDirection;
														// if sortDirection is null then we set initial direction to ASC order
														sort(sortDirection ? updatedDirection : sortDirectionEnum.ASC);
													}
												}}
											>
												<Box display={"flex"} justifyContent={alignment}>
													{label}
													{filter && <ColumnFilterWrapper filter={filter} />}
													{sort && (
														<SortArrowWrapper sortDirection={sortDirection}>
															<ArrowDropUp /> <ArrowDropDown />
														</SortArrowWrapper>
													)}
												</Box>
											</TableCell>
										);
									})}
								</TableRow>
							</TableHead>
						)}

						<TableBody>
							{data.map((item, index) => (
								<Row
									key={uuid()}
									index={index}
									item={item}
									childItems={item.childItems || []}
									disabled={disableRow && disableRow(item)}
									columns={columns}
									customCellStyle={rowCustomCellStyle}
									showNestedRowsIcon={showNestedRowsIcon}
									onRowClick={() => onRowClick && onRowClick(item)}
									selectedRows={selectedRows}
									{...(onRowSelect ? { onRowSelect: () => onRowSelect && onRowSelect(item) } : {})}
								/>
							))}
						</TableBody>
					</Table>
				</>
			)}
		</TableContainer>
	);
};

export default MaterialTable;
