import React, { useState } from "react";

import { Box, TextField, Typography } from "@material-ui/core";
import Button from "@remar/shared/dist/components/Button/Button";

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeCardElement } from "@stripe/stripe-js/types/stripe-js/elements";
import { useAppDispatch, useAppSelector } from "store";
import {
	getFullState as getFullBillingState,
	getPaymentDetails,
	updatePaymentMethod
} from "store/features/Billing/BillingSlice";

interface PaymentUpdateThunk {
	CardElement: typeof CardElement;
	element: (card: typeof CardElement) => StripeCardElement;
	stripe: ReturnType<typeof useStripe>;
	sideEffect: (error: string) => void;
}
const CardForm = ({ onClose }) => {
	const [cardHolderName, setCardHolderName] = useState("");
	const [validCardDetails, setValidCardDetails] = useState(false);
	const { isLoading } = useAppSelector(getFullBillingState);
	const elements = useElements();
	const stripe = useStripe();
	const dispatch = useAppDispatch();
	const cardHolderNameHandler = e => {
		setCardHolderName(e.target.value);
	};
	const handleAddCardDetails = () => {
		const updatePayload = {
			CardElement,
			stripe,
			element: elements?.getElement,
			sideEffect: (error: string) => {
				if (error) {
					console.error(error);
				} else {
					dispatch(getPaymentDetails());
					onClose();
				}
			}
		};
		dispatch(updatePaymentMethod(updatePayload as PaymentUpdateThunk));
	};

	return (
		<Box my={2}>
			<Box width={"100%"} my={2}>
				<Typography variant={"subtitle2"} color={"textSecondary"} style={{ marginBottom: "5px" }}>
					Cardholder’s Name
				</Typography>

				<TextField
					InputProps={{ disableUnderline: true }}
					variant="filled"
					hiddenLabel
					placeholder="Full Name"
					fullWidth
					value={cardHolderName}
					onChange={cardHolderNameHandler}
				/>
			</Box>

			<Box>
				<Typography variant={"subtitle2"} color={"textSecondary"} style={{ marginBottom: "5px" }}>
					Billing Details
				</Typography>
				<CardElement
					options={{
						style: {
							base: {
								fontSize: "18px",
								color: "#c2c2c2",
								"::placeholder": {
									color: "#aab7c4"
								}
							},
							invalid: {
								color: "#9e2146"
							}
						}
					}}
					onChange={event => {
						const { complete } = event;
						setValidCardDetails(complete);
					}}
				/>
			</Box>

			<Box mt={6}>
				<Box width={"100%"} display="flex" justifyContent={"space-between"}>
					<Button variant={"ghost"} onClick={onClose}>
						Cancel
					</Button>
					<Button
						variant={"filled"}
						onClick={handleAddCardDetails}
						disabled={isLoading || !cardHolderName || !validCardDetails}
						loading={isLoading}
					>
						Save Card Details
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default CardForm;
