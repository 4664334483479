import React, { FC } from "react";

import { Box, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import Button from "@remar/shared/dist/components/Button";
import RecaptchaTerms from "@remar/shared/dist/components/ReCaptchaTerms";

import { Link } from "react-router-dom";

import { routes } from "core/constants";

import { PaymentButtonsContainer } from "./styles";

export const SimpleFormActions: FC<
	Pick<SignUpActionsProps, "disabled" | "step" | "back" | "next" | "loading" | "valid" | "firstStep" | "lastStep">
> = ({ disabled, step, back, next, loading, valid, firstStep = 0, lastStep = 2 }) => {
	const theme = useTheme<Theme>();
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
	const isFirstStep = step === firstStep;
	const CompletePurchaseWidth = isMobile ? "100%" : step === lastStep ? "60%" : "50%";

	return (
		<PaymentButtonsContainer>
			{!isFirstStep && (
				<Box style={{ width: isMobile ? "100%" : "50%" }}>
					<Button style={{ width: "100%" }} color="secondary" variant="filled" onClick={back}>
						Back
					</Button>
				</Box>
			)}
			<Box ml={isMobile ? 0 : 2} mb={isMobile ? "10px" : 0} style={{ width: CompletePurchaseWidth }}>
				<Button
					color="primary"
					disabled={!valid || loading || disabled}
					loading={loading}
					variant="filled"
					style={{ width: "100%", padding: "10px 6px" }}
					onClick={() => next()}
				>
					{step === lastStep ? "Complete Purchase" : "Next"}
				</Button>
			</Box>
		</PaymentButtonsContainer>
	);
};

interface SignUpActionsProps {
	step: number;
	valid: boolean;
	loading: boolean;
	next: (isTrial?: boolean) => void;
	back: () => void;
	firstStep?: number;
	lastStep?: number;
	upgradeSubscription?: boolean;
	disabled?: boolean;
	showSignIn?: boolean;
}

const FormActions: FC<SignUpActionsProps> = ({
	step,
	back,
	next,
	loading,
	valid,
	firstStep = 0,
	lastStep = 2,
	disabled = false,
	upgradeSubscription = false,
	showSignIn = true
}) => {
	return (
		<>
			<SimpleFormActions disabled={disabled} step={step} valid={valid} loading={loading} next={next} back={back} />

			{step === firstStep && !upgradeSubscription && showSignIn && (
				<Link to={routes.signIn.getPath()}>
					<Typography color="textSecondary">Already have an account?</Typography>
				</Link>
			)}
			{step === lastStep && (
				<Box mt={2}>
					<RecaptchaTerms />
				</Box>
			)}
		</>
	);
};

export default FormActions;
